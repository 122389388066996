import React, { useEffect, useState } from "react";
import Loading from "../Loading";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { transactionAll } from "../../actions/transaction_actions";
import PrivateSecurity from "../common/PrivateSecurity";
import moment from "moment";
const Transaction = ({ transactionAll, data, success }) => {
  const [dataNotice, setdataNotice] = useState([]);

  useEffect(() => {
    if (dataNotice.length === 0) transactionAll();
    if (success || data.length !== dataNotice.length) {

      setdataNotice(data);
    }
  }, [data, dataNotice.length, success, transactionAll]);
  return (
    <PrivateSecurity title={"Transacciones"}>
      <div className="card full-height consultation-list">
        <div className="card-header">
          <div className="d-flex align-items-center">
            <div className="card-title">
              <h4
                style={{
                  fontFamily: "Poppins",
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Listado
              </h4>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          {!success ? (
            <div className="GridLoader">
              <Loading />
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th className="text-center">Plan</th>
                    <th className="text-center">Banco </th>
                    <th className="text-center">Tipo</th>
                    <th className="text-center">Monto</th>
                    <th className="text-center">Referencia</th>
                    <th className="text-center">Fecha</th>
                    <th className="text-center">Estatus</th>
                  </tr>
                  {dataNotice.length > 0 ? (
                    dataNotice.map((data, index) => {
                      let status;
                      if (!data.rejectd_at) {
                        switch (data.status) {
                          case "approved":
                            status = "Aprobado";

                            break;
                          case "rejected":
                            status = "Rechazado";

                            break;

                          default:
                            status = "Por definir";
                            break;
                        }
                      }

                      return (
                        <tr key={index}>
                          <td className="text-center">{data.concept.name}</td>
                          <td className="text-center">
                            {data.payment_detail.bank}
                          </td>
                          <td className="text-center">
                            {data.type_transaction}
                          </td>
                          <td className="text-center">
                            {data.payment_amount.usd_amount}
                          </td>

                          <td className="text-center">
                            {data.payment_detail.reference}
                          </td>

                          <td className="text-center">
                            {moment(data.created_at).format("DD-MM-YYYY")}
                          </td>
                          <td className="text-center">{status}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7} className="text-center">
                        No hay transacciones actualmente
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </PrivateSecurity>
  );
};

const mapStateToProps = state => ({
  data: state.transaction.data,
  success: state.transaction.success
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ transactionAll }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Transaction);
