import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login } from "../../actions/user_actions";

import Logo from "../../img/iconsGradient.png";

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = "Obligatorio";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values && values.email)
  ) {
    errors.email = "Dirección de correo electrónico no válida";
  }

  if (!values.password) {
    errors.password = "Obligatorio";
  } else if (values.password.length < 6) {
    errors.password = "Debe tener 6 caracteres o mas";
  } else if (!/^[a-zA-ZñÑ10-9]+$/.test(values.password)) {
    errors.password = "Caracteres no validos";
  }

  return errors;
};

const renderField = ({
  input,
  name,
  className,
  type,
  placeholder,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <input
          className={className}
          {...input}
          type={type}
          placeholder={placeholder}
        />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      user: {}
    };
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div className="login-aside w-50 d-flex align-items-center justify-content-center bg-white">
        <div className="container container-login container-transparent animated fadeIn">
          <div className="login-form">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 20,
                marginTop: -50
              }}
            >
              <img width="100" height="100" src={Logo} alt="logo" />
            </div>

            <h1 className="text-center">Novem</h1>
            <form onSubmit={handleSubmit(values => this.props.login(values))}>
              <div className="form-group">
                <label className="placeholder">
                  <b>Email</b>
                </label>
                <Field
                  className="form-control"
                  name="email"
                  component={renderField}
                  type="email"
                  placeholder="Email"
                />
              </div>
              <div className="form-group">
                <label className="placeholder">
                  <b>Contraseña</b>
                </label>
                <div className="position-relative">
                  <Field
                    className="form-control"
                    name="password"
                    component={renderField}
                    type={this.state.hidden ? "password" : "text"}
                    placeholder="Contraseña"
                  />
                  <div className="show-password">
                    <button
                      style={{
                        position: "absolute",
                        top: -25,
                        left: -30,
                        right: 5,
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        color: "#19769f"
                      }}
                      onClick={this.toggleShow}
                      type="button"
                    >
                      <i
                        className={
                          this.state.hidden ? "far fa-eye" : "far fa-eye-slash"
                        }
                      ></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="login-account" style={{ marginBottom: 15 }}>
                <span className="msg">¿No tienes cuenta?</span>
                <Link
                  className="link"
                  to="/SignUp"
                  style={{ marginLeft: "1rem" }}
                >
                  Registrate
                </Link>
              </div>
              <div className="login-account" style={{ marginBottom: 15 }}>
                <Link
                  className="link"
                  to="/LostPassword"
                  style={{ marginLeft: "1rem" }}
                >
                  <span className="msg">¿Olvidaste tu contraseña?</span>
                </Link>
              </div>
              <div>
                <button
                  className="btn btn-primary col-md-5 float-right mt-3 mt-sm-0 fw-bold"
                  type="submit"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

/*const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user
  };
};*/

const mapDispatchToProps = dispatch => bindActionCreators({ login }, dispatch);

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  reduxForm({
    form: "Login",
    validate
  })
)(Login);
