import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
			<footer className="footer">
				<div className="container-fluid">
					<nav className="pull-left">
						<ul className="nav">
                            <li className="nav-item">
								<a className="nav-link" href="https://www.novemapp.com/politicas.pdf">
									Políticas de privacidad
								</a>
                            </li>
						</ul>
					</nav>
					<div className="copyright ml-auto">
						Hecho con <i className="fa fa-heart heart text-danger"></i> por <a href="https://www.novemapp.com">NovemApp</a>, 2022 Copyright 
					</div>
				</div>
			</footer>
        )
    }
}
