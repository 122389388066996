import React, { useEffect /*useState */ } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { transferPay } from "../../actions/suscription_actions";
import { renderField, renderFieldSelect } from "../form/field";
import { validate } from "../form/validate";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const DATA = [
  { label: "Cédula", type: "text", name: "dni" },
  { label: "Fecha de pago", type: "date", max: true, name: "date" },
  { label: "Referencia", type: "text", name: "reference" }
];

let FormPay = ({
  user,
  initTransfer,
  transferPay,
  handleSubmit,
  change,
  suscription_id,
  close,
  back
}) => {
  useEffect(() => {
    change("name", user.user.name);
    change("lastname", user.user.lastname);
  });

  const submit = data => {
    let amountString = initTransfer.usd_cost.toString().replace(".", ",")
    let values = { 
      suscription_id: suscription_id,
      bank_id: "5effde81c87a95e2fad040eb",
      paymnent_detail: {
        bank: "Banesco",
        headline: `${data.name} ${data.lastname}`,
        dni: data.dni,
        reference: data.reference,
        amount: amountString,
        date: data.date
      }
    };
    close();
    back();
    transferPay(values, "transfer");
  };

  return (
    <div className="card-body">
      <div className="form-group">
        <div className="row">
          {DATA.map((item, i) => (
            <div className="col-md-12" key={i}>
              <Field
                name={item.name}
                disabled={item.disabled}
                className="form-control"
                component={
                  item.type === "select" ? renderFieldSelect : renderField
                }
                type={item.type}
                max={item.max ? moment().format("YYYY-MM-DD") : null}
                label={item.label}
              >
                {item.type === "select"
                  ? initTransfer.national_banks.map((option, i) => (
                      <option value={option._id} key={i}>
                        {option.name}
                      </option>
                    ))
                  : null}
              </Field>
              {item.type === "select" && (
                <>
                  <br />
                  <br />
                </>
              )}
            </div>
          ))}
        </div>
        <button
          className="btn"
          style={{ backgroundColor: "#19769F", color: "#ffffff" }}
          onClick={handleSubmit(submit)}
        >
          Enviar
        </button>
      </div>
    </div>
  );
};

FormPay = reduxForm({
  form: "FormPay",
  validate
})(FormPay);

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    initTransfer: state.suscription.initTransfer
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      transferPay
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormPay);
