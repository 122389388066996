import {
  SUCCESS,
  INIT_INSURANCE,
  EDIT_USER,
  PHOTO,
  EDIT_PHOTO,
  SHOW_MODAL,
  EMAIL_FORGET_PASSWORD,
  TOKEN_NEW_PASSWORD,
  LOADING,
  LIBRARY_ALL_BY_TYPE,
  TOOTH_LIBRARY,
  TERM_VALUE,
  TOOTH_TERM_VALUE,
  INTRO_SHOW,
  NOTIFICATION,
  SECURITYLOGIN,
  SUCCESSREFRESH,
} from "../actions/user_actions";
import {
  LIST_RESERVED_APPOINTMENT,
  LIST_CONFIRMED_APPOINTMENT,
  USER_APPOINTMENT,
  APPOINTMENT_ITEM,
  LIST_PATIENTS,
  EMD_PATIENT,
  NEW_APPOINTMENT,
  APPOINTMENT_USER,
  TYPE_FORMULARY,
  MEDICAL_CONSULTATION_PDF,
  NOT_SHOW,
  FAILURE_FIND,
  SUCCESS_FIND,
  LOADING_FIND,
  LOADING_PATIENT,
} from "../actions/appointment_actions";
import {
  INIT_SPECIALTY,
  ADD_SPECIALTY,
  ADD_OFFICE,
  DELETE_OFFICE,
  ADD_SCHEDULE,
  DELETE_SCHEDULE,
  ADD_DOCUMENT,
  ADD_NUMBER_DOCTOR,
  ADD_NON_WORKING_DAY,
  DELETE_NON_WORKING_DAY,
  ADD_INSURANCE,
  DATA_APPOINTMENT,
  CALENDAR_VALUES,
  DELETE_SPECIALTY,
  DELETE_DOCUMENT,
} from "../actions/doctor_actions";

import {
  ALLERGY_SAVE,
  ALLERGY_DELETE,
  ALLERGY_VERIFICATE,
  TOOTH_SAVE,
  TOOTH_DELETE,
  TOOTH_VERIFICATE,
  PATHOLOGY_SAVE,
  PATHOLOGY_DELETE,
  PATHOLOGY_VERIFICATE,
  SURGICAL,
  HABIT,
  FAMILY,
  IMC,
  BLOOD_PRESSURE,
  ADRESS_LOADING,
  PATHOLOGY_LOADING,
  ALLERGY_LOADING,
  TOOTH_LOADING,
  SURGICAL_LOADING,
  HABIT_LOADING,
  IMC_LOADING,
  FAMILY_LOADING,
  BLOOD_PRESSURE_LOADING,
  ADRESS,
  BIRTHDATE,
  BIRTHDATE_LOADING,
} from "../actions/emd_actions";

const inicialState = {
  loading: false,
  error: null,
  showNot: false,
  user: {},
  tokenSecurity: false,
  reserved: [],
  confirmed: [],
  specialty: [],
  appointment: [],
  vacation: [],
  listInsurance: [],
  patient: [],
  patients: [],
  emdPatient: [],
  data: [],
  selectEMD: [],
  formulary: [],
  modal: false,
  message: "",
  calendar: [],
  emailForget: [],
  tokenNewPassword: [],
  photo: "",
  library_all_by_type: [],
  toothLibrary: [],
  termValue: "",
  toothTermValue: "",
  notification2: [],
  pdf: false,
  loadingFind: false,
  successFind: false,
  errorFind: false,
  msgFind: "",
  isLoading: true,
  isAuthenticated: false,
  loadingPathology: false,
  loadingImc: false,
  loadingHabit: false,
  loadingFamily: false,
  loadingAdress: false,
  loadingBloodPressure: false,
  loadingSurgical: false,
  loadingAllergy: false,
  loadingTooth: false,
  loadingPatient: false,
  loadingBirthdate: false,
  typeDocuments: [
    { name: "Profesión" },
    { name: "Post grado" },
    { name: "Maestría" },
  ],
};

export function reducer(state = inicialState, action) {
  try {
    switch (action.type) {
      case MEDICAL_CONSULTATION_PDF:
        return {
          ...state,
          pdf: action.data,
        };
      case LOADING_FIND:
        return {
          ...state,
          loadingFind: true,
        };
      case SUCCESS_FIND:
        return {
          ...state,
          successFind: true,
          loadingFind: false,
          errorFind: false,
        };

      case FAILURE_FIND:
        return {
          ...state,
          successFind: false,
          loadingFind: false,
          errorFind: true,
          msgFind: action.msg,
        };

      case NOT_SHOW:
        return {
          ...state,
          showNot: true,
        };
      case INTRO_SHOW:
        let intro = { ...state.user };
        intro.medical.intro = action.data;
        return {
          ...state,
          user: intro,
        };
      case NOTIFICATION:
        let notification = [...state.notification2];
        notification.push(action.data);

        return {
          ...state,
          notification2: notification,
        };
      case LOADING:
        return {
          ...state,
          loading: action.data,
        };
      case TERM_VALUE:
        return {
          ...state,
          termValue: action.data,
        };
        case TOOTH_TERM_VALUE:
        return {
          ...state,
          toothTermValue: action.data,
        };
        
      case PHOTO:
        return {
          ...state,
          photo: action.data,
        };
      case TOKEN_NEW_PASSWORD:
        return {
          ...state,
          tokenNewPassword: action.token,
        };

      case EMAIL_FORGET_PASSWORD:
        return {
          ...state,
          emailForget: action.email,
        };
      case CALENDAR_VALUES:
        return {
          ...state,
          calendar: action.data,
        };

      case SHOW_MODAL:
        return {
          ...state,
          modal: action.data.open,
          message: action.data.message,
        };
      case TYPE_FORMULARY:
        return {
          ...state,
          formulary: action.result,
        };
      case DATA_APPOINTMENT:
        return {
          ...state,
          data: action.data,
        };
      case APPOINTMENT_USER:
        return {
          ...state,
          selectEMD: action.data,
        };
      case SUCCESS:
        return {
          ...state,
          user: action.data,
        };
      case SUCCESSREFRESH:
        return {
          ...state,
          isLoading: false,
          isAuthenticated: true,
        };
      case INIT_INSURANCE:
        return {
          ...state,
          listInsurance: action.data,
        };
      case LIST_RESERVED_APPOINTMENT:
        return {
          ...state,
          reserved: action.result,
        };
      case LIST_PATIENTS:
        return {
          ...state,
          patients: action.result,
          loadingPatient: false,
        };
      case LIST_CONFIRMED_APPOINTMENT:
        return {
          ...state,
          confirmed: action.result,
        };
      case INIT_SPECIALTY:
        return {
          ...state,
          specialty: action.data,
        };
      case USER_APPOINTMENT:
        return {
          ...state,
          appointment: action.result,
        };
      case APPOINTMENT_ITEM:
        return {
          ...state,
          patient: action.result,
        };
      case ALLERGY_SAVE:
        let appointment = { ...state.appointment };

        if (action.year) {
          if (!appointment.Health.MedicalRecord) {
            appointment.Health["MedicalRecord"] = {
              Alergies: {
                yearTest: action.year,
              },
            };
          } else if (!appointment.Health.MedicalRecord.Alergies.yearTest) {
            appointment.Health.MedicalRecord.Alergies["yearTest"] = action.year;
          } else
            appointment.Health.MedicalRecord.Alergies.yearTest = action.year;
        }

        if (Object.entries(appointment.Health.MedicalRecord).length === 0) {
          appointment.Health["MedicalRecord"] = {
            Alergies: {
              Alergies: action.data,
            },
          };
        } else if (!appointment.Health.MedicalRecord.Alergies) {
          appointment.Health.MedicalRecord.Alergies["Alergies"] = action.data;
        } else appointment.Health.MedicalRecord.Alergies.Alergies = action.data;

        return {
          ...state,
          appointment: appointment,
          loadingAllergy: false,
        };
      case ALLERGY_DELETE:
        appointment = { ...state.appointment };
        appointment.Health.MedicalRecord.Alergies.Alergies = action.data;
        return {
          ...state,
          appointment: appointment,
          loadingAllergy: false,
        };
      case ALLERGY_VERIFICATE:
        appointment = { ...state.appointment };
        var index = appointment.Health.MedicalRecord.Alergies.Alergies.indexOf(
          action.item
        );
        appointment.Health.MedicalRecord.Alergies.Alergies.splice(index, 1);
        appointment.Health.MedicalRecord.Alergies.Alergies.push(action.data);
        return {
          ...state,
          appointment: appointment,
          loadingAllergy: false,
        };

      case TOOTH_SAVE:
        appointment = { ...state.appointment };

        if (action) {
          if (!appointment.Health.MedicalRecord) {
            appointment.Health["MedicalRecord"] = {
              Teeth: action,
            };
          } else if (!appointment.Health.MedicalRecord.Alergies.yearTest) {
            appointment.Health.MedicalRecord.Teeth["name"] = action.year;
          } else
            appointment.Health.MedicalRecord.Alergies.yearTest = action.year;
        }

        if (Object.entries(appointment.Health.MedicalRecord).length === 0) {
          appointment.Health["MedicalRecord"] = {
            Teeth: {
              Teeth: action.data,
            },
          };
        } else if (!appointment.Health.MedicalRecord.Teeth) {
          appointment.Health.MedicalRecord.Teeth["Teeth"] = action.data;
        } else appointment.Health.MedicalRecord.Teeth.Teeth = action.data;

        return {
          ...state,
          appointment: appointment,
          loadingTooth: false,
        };
      case TOOTH_DELETE:
        appointment = { ...state.appointment };
        appointment.Health.MedicalRecord.Teeth.Teeth = action.data;
        return {
          ...state,
          appointment: appointment,
          loadingTooth: false,
        };
      case TOOTH_VERIFICATE:
        appointment = { ...state.appointment };
        index = appointment.Health.MedicalRecord.Teeth.Teeth.indexOf(
          action.item
        );
        appointment.Health.MedicalRecord.Teeth.Teeth.splice(index, 1);
        appointment.Health.MedicalRecord.Teeth.Teeth.push(action.data);
        return {
          ...state,
          appointment: appointment,
          loadingTooth: false,
        };
      
      case IMC:
        appointment = { ...state.appointment };

        if (action.name === "save") {
          if (typeof appointment.Health === "undefined") {
            appointment["Health"] = {
              MedicalMeasurement: {
                height: [action.data.height[0]],
                weight: [action.data.weight[0]],
                imc: [action.data.imc[0]],
              },
            };
          }
          if (
            typeof appointment.Health.MedicalMeasurement === "undefined" ||
            typeof appointment.Health.MedicalMeasurement.height === "undefined"
          ) {
            appointment.Health["MedicalMeasurement"] = {
              height: [action.data.height[0]],
              weight: [action.data.weight[0]],
              imc: [action.data.imc[0]],
            };
          } else {
            appointment.Health.MedicalMeasurement.height.push(
              action.data.height[0]
            );
            appointment.Health.MedicalMeasurement.weight.push(
              action.data.weight[0]
            );
            appointment.Health.MedicalMeasurement.imc.push(action.data.imc[0]);
          }
        }
        if (action.name === "verificate") {
          appointment.Health.MedicalMeasurement.height.splice(
            action.position,
            1
          );
          appointment.Health.MedicalMeasurement.weight.splice(
            action.position,
            1
          );
          appointment.Health.MedicalMeasurement.imc.splice(action.position, 1);

          appointment.Health.MedicalMeasurement.height.push(
            action.data.MedicalMeasurement.height[0]
          );
          appointment.Health.MedicalMeasurement.weight.push(
            action.data.MedicalMeasurement.weight[0]
          );
          appointment.Health.MedicalMeasurement.imc.push(
            action.data.MedicalMeasurement.imc[0]
          );
        }
        if (action.name === "delete") {
          appointment.Health.MedicalMeasurement.height.splice(
            action.position,
            1
          );
          appointment.Health.MedicalMeasurement.weight.splice(
            action.position,
            1
          );
          appointment.Health.MedicalMeasurement.imc.splice(action.position, 1);
        }

        return {
          ...state,
          appointment: appointment,
          loadingImc: false,
        };
      case PATHOLOGY_SAVE:
        appointment = { ...state.appointment };
        if (!appointment.Health.MedicalRecord) {
          appointment.Health["MedicalRecord"] = {
            PathologyHistories: action.data,
          };
        } else
          appointment.Health.MedicalRecord.PathologyHistories = action.data;
        return {
          ...state,
          appointment: appointment,
          loadingPathology: false,
        };
      case PATHOLOGY_DELETE:
        appointment = { ...state.appointment };
        appointment.Health.MedicalRecord.PathologyHistories = action.data;
        return {
          ...state,
          appointment: appointment,
          loadingPathology: false,
        };
      case PATHOLOGY_VERIFICATE:
        appointment = { ...state.appointment };
        index = appointment.Health.MedicalRecord.PathologyHistories.indexOf(
          action.item
        );
        appointment.Health.MedicalRecord.PathologyHistories.splice(index, 1);
        appointment.Health.MedicalRecord.PathologyHistories.push(action.data);
        return {
          ...state,
          appointment: appointment,
          loadingPathology: false,
        };
      case SURGICAL:
        appointment = { ...state.appointment };
        if (action.name === "save" || action.name === "delete") {
          if (!appointment.Health.MedicalRecord) {
            appointment.Health["MedicalRecord"] = {
              SurgicalHistories: action.data,
            };
          } else
            appointment.Health.MedicalRecord.SurgicalHistories = action.data;
        }
        if (action.name === "verificate") {
          index = appointment.Health.MedicalRecord.SurgicalHistories.indexOf(
            action.item
          );
          appointment.Health.MedicalRecord.SurgicalHistories.splice(index, 1);
          appointment.Health.MedicalRecord.SurgicalHistories.push(action.data);
        }

        return {
          ...state,
          appointment: appointment,
          loadingSurgical: false,
        };

      case BLOOD_PRESSURE:
        appointment = { ...state.appointment };

        if (action.name === "save") {
          if (!appointment.Health.MedicalMeasurement) {
            appointment.Health["MedicalMeasurement"] = {
              blood_pressures: [action.data],
            };
          } else if (!appointment.Health.MedicalMeasurement.blood_pressures)
            appointment.Health.MedicalMeasurement["blood_pressures"] = [
              action.data,
            ];
          else {
            appointment.Health.MedicalMeasurement.blood_pressures.push(
              action.data
            );
          }
        }
        if (action.name === "delete") {
          index = appointment.Health.MedicalMeasurement.blood_pressures.indexOf(
            action.item
          );
          appointment.Health.MedicalMeasurement.blood_pressures.splice(
            index,
            1
          );
        }
        if (action.name === "verificate") {
          index = appointment.Health.MedicalMeasurement.blood_pressures.indexOf(
            action.item
          );
          appointment.Health.MedicalMeasurement.blood_pressures.splice(
            index,
            1
          );
          appointment.Health.MedicalMeasurement.blood_pressures.push(
            action.data
          );
        }

        return {
          ...state,
          appointment: appointment,
          loadingBloodPressure: false,
        };

      case HABIT:
        appointment = { ...state.appointment };
        if (action.name === "save" || action.name === "delete") {
          if (!appointment.Health.MedicalRecord) {
            appointment.Health["MedicalRecord"] = {
              Habits: action.data,
            };
          } else appointment.Health.MedicalRecord.Habits = action.data;
        }
        if (action.name === "verificate") {
          index = appointment.Health.MedicalRecord.Habits.indexOf(action.item);
          appointment.Health.MedicalRecord.Habits.splice(index, 1);
          appointment.Health.MedicalRecord.Habits.push(action.data);
        }

        return {
          ...state,
          appointment: appointment,
          loadingHabit: false,
        };
      case FAMILY:
        appointment = { ...state.appointment };
        if (action.name === "save" || action.name === "delete") {
          if (!appointment.Health.MedicalRecord) {
            appointment.Health["MedicalRecord"] = {
              FamilyHistories: action.data,
            };
          } else appointment.Health.MedicalRecord.FamilyHistories = action.data;
        }
        if (action.name === "verificate") {
          index = appointment.Health.MedicalRecord.FamilyHistories.indexOf(
            action.item
          );
          appointment.Health.MedicalRecord.FamilyHistories.splice(index, 1);
          appointment.Health.MedicalRecord.FamilyHistories.push(action.data);
        }

        return {
          ...state,
          appointment: appointment,
          loadingFamily: false,
        };
      case ADD_SPECIALTY:
        let value = { ...state.user };
        value.medical.specialties.push(action.data);

        return {
          ...state,
          user: value,
        };
      case DELETE_SPECIALTY:
        let specialtyDelete = { ...state.user };

        var i = specialtyDelete.medical.specialties.indexOf(action.data);
        specialtyDelete.medical.specialties.splice(i, 1);

        return {
          ...state,
          user: specialtyDelete,
        };
      case ADD_OFFICE:
        let office = { ...state.user };
        office.medical.offices.push(action.data);

        return {
          ...state,
          user: office,
        };
      case DELETE_OFFICE:
        office = { ...state.user };
        i = office.medical.offices.indexOf(action.data);
        office.medical.offices.splice(i, 1);

        return {
          ...state,
          user: office,
        };
      case ADD_SCHEDULE:
        let schedule = { ...state.user };

        schedule.medical.schedules.push(action.data);
        return {
          ...state,
          user: schedule,
        };
      case DELETE_SCHEDULE:
        schedule = { ...state.user };

        i = schedule.medical.schedules.indexOf(action.data);
        schedule.medical.schedules.splice(i, 1);

        return {
          ...state,
          user: schedule,
        };
      case ADD_DOCUMENT:
        let values = { ...state.user };

        values.medical.documents.push(action.data);
        return {
          ...state,
          user: values,
        };

      case DELETE_DOCUMENT:
        values = { ...state.user };

        i = values.medical.documents.indexOf(action.data);

        values.medical.documents.splice(i, 1);

        return {
          ...state,
          user: values,
        };
      case ADD_NUMBER_DOCTOR:
        let numberDoctor = { ...state.user };

        if (!action.type) {
          numberDoctor.medical.medical_number = action.data.medical_number;
        } else {
          numberDoctor.medical.governmental_number =
            action.data.governmental_number;
        }

        return {
          ...state,
          user: numberDoctor,
        };
      case ADD_INSURANCE:
        let insuranceItem = { ...state.user };

        insuranceItem.medical.insurances.push(action.data);
        return {
          ...state,
          user: insuranceItem,
        };

      case NEW_APPOINTMENT:
        return {
          ...state,
        };
      case EDIT_USER:
        let edituser = { ...state.user };

        edituser.user.name = action.data.name;
        edituser.user.lastname = action.data.lastname;
        edituser.user.gender = action.data.gender;
        edituser.user.country = action.data.country;
        edituser.user.city = action.data.city;
        edituser.user.birthdate = action.data.birthdate;
        edituser.user.address = action.data.address;

        return {
          ...state,
          user: edituser,
        };
      case EDIT_PHOTO:
        let editphoto = { ...state.user };

        editphoto.user.picture = action.data;

        return {
          ...state,
          user: editphoto,
        };
      case ADD_NON_WORKING_DAY:
        editphoto = { ...state.user };
        editphoto.medical.vacations.push(action.data);
        return {
          ...state,
          user: editphoto,
        };
      case DELETE_NON_WORKING_DAY:
        values = { ...state.user };

        i = values.medical.vacations.indexOf(action.data);
        values.medical.vacations.splice(i, 1);

        return {
          ...state,
          user: values,
        };
      case EMD_PATIENT:
        return {
          ...state,
          emdPatient: action.data,
        };
      case LIBRARY_ALL_BY_TYPE:
        return {
          ...state,
          library_all_by_type: action.result,
        };
      case TOOTH_LIBRARY:
        return {
          ...state,
          toothLibrary: action.result,
        };
        
      case SECURITYLOGIN:
        return {
          ...state,
          tokenSecurity: true,
        };
      case ADRESS:
        appointment = { ...state.appointment };
        appointment.city = action.data.city;
        appointment.country = action.data.country;
        appointment.address = action.data.address;
        return {
          ...state,
          appointment: appointment,
          loadingAdress: false,
        };
      case BIRTHDATE:
        appointment = { ...state.appointment };
        appointment.birthdate = action.data.date;
        appointment.age = action.data.age;
        return {
          ...state,
          appointment: appointment,
          loadingBirthdate: false,
        };
      case ADRESS_LOADING:
        return {
          ...state,
          loadingAdress: true,
        };
      case BLOOD_PRESSURE_LOADING:
        return {
          ...state,
          loadingBloodPressure: true,
        };
      case IMC_LOADING:
        return {
          ...state,
          loadingImc: true,
        };
      case FAMILY_LOADING:
        return {
          ...state,
          loadingFamily: true,
        };
      case HABIT_LOADING:
        return {
          ...state,
          loadingHabit: true,
        };
      case SURGICAL_LOADING:
        return {
          ...state,
          loadingSurgical: true,
        };
      case ALLERGY_LOADING:
        return {
          ...state,
          loadingAllergy: true,
        };

      case TOOTH_LOADING:
        return {
          ...state,
          loadingTooth: true,
        };
      
      case PATHOLOGY_LOADING:
        return {
          ...state,
          loadingPathology: true,
        };
      case LOADING_PATIENT:
        return {
          ...state,
          loadingPatient: true,
        };
      case BIRTHDATE_LOADING:
        return {
          ...state,
          loadingBirthdate: true,
        };
      default:
        return state;
    }
  } catch (e) {
    console.log(e);
  }
}
