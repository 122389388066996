import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addSchedule, deleteSchedule } from "../../../actions/doctor_actions";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Multiselect from "react-widgets/lib/Multiselect";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { showModal } from "../../../actions/user_actions";

import "moment/locale/es";
import "react-widgets/dist/css/react-widgets.css";
moment.locale("es");
/*import Header from "../../../components/Header";
import Sidebar from "../../../components/sidebar/Sidebar";
import UserGreet from "../../../consts/UserGreet";
import SessionId from "./SessionId";*/

const renderMultiselect = ({ input, data, valueField, textField }) => (
  <Multiselect
    {...input}
    onBlur={() => input.onBlur()}
    value={input.value || []} // requires value to be an array
    data={data}
    valueField={valueField}
    textField={textField}
  />
);

const orden = [
  "Lunes",
  "Martes",
  "Miercoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo"
];

const validate = values => {
  const errors = {};

  if (!values.clinic) {
    errors.clinic = "Obligatorio";
  }

  if (!values.specialty) {
    errors.specialty = "Obligatorio";
  }

  if (!values.day) {
    errors.day = "Obligatorio";
  }
  if (!values.quotas) {
    errors.quotas = "Obligatorio";
  }
  if (!values.intervals) {
    errors.intervals = "Obligatorio";
  }

  return errors;
};

const days = [
  {
    _id: "0",
    es: {
      name: "Domingo",
      abbreviation: "dom"
    },
    en: {
      name: "Sunday",
      abbreviation: "sun"
    }
  },
  {
    _id: "1",
    es: {
      name: "Lunes",
      abbreviation: "lun"
    },
    en: {
      name: "Monday",
      abbreviation: "mon"
    }
  },
  {
    _id: "2",
    es: {
      name: "Martes",
      abbreviation: "mar"
    },
    en: {
      name: "Tuesday",
      abbreviation: "tue"
    }
  },
  {
    _id: "3",
    es: {
      name: "Miercoles",
      abbreviation: "mier"
    },
    en: {
      name: "Wednesday",
      abbreviation: "wed"
    }
  },
  {
    _id: "4",
    es: {
      name: "Jueves",
      abbreviation: "jue"
    },
    en: {
      name: "Thursday",
      abbreviation: "thu"
    }
  },
  {
    _id: "5",
    es: {
      name: "Viernes",
      abbreviation: "vie"
    },
    en: {
      name: "Friday",
      abbreviation: "fri"
    }
  },
  {
    _id: "6",
    es: {
      name: "Sábado",
      abbreviation: "sab"
    },
    en: {
      name: "Saturday",
      abbreviation: "sat"
    }
  }
];

const renderField = ({
  input,
  name,
  className,
  type,
  options,
  children,
  meta: { touched, error }
}) => {
  return (
    <div>
      <select className={className} {...input}>
        {children}
      </select>

      {touched && (error && <span>{error}</span>)}
    </div>
  );
};

class AppoinmentConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      since: "",
      until: "",
      sinceComplete: "",
      untilComplete: "",
      modal: false,
      send: true,
      delete: false,
      error: false,
      item: "",
      data2: []
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ modal: false });
  }

  handleUntil = value => {
    if (
      value.format("DD-MM-YYYY HH:mm") <=
      this.state.sinceComplete.format("DD-MM-YYYY HH:mm")
    ) {
      this.setState({
        error: "Debe ser una hora válida"
      });
    } else
      this.setState({
        until: value.format("HH:mm"),
        send: false,
        untilComplete: value,
        error: false
      });
  };

  handleSince = value => {
    if (
      this.state.untilComplete &&
      value.format("DD-MM-YYYY HH:mm") >=
        this.state.untilComplete.format("DD-MM-YYYY HH:mm")
    ) {
      this.setState({
        error: "Debe ser una hora válida"
      });
    } else
      this.setState({
        since: value.format("HH:mm"),
        sinceComplete: value,
        error: false
      });
  };

  static getDerivedStateFromProps(props, state) {
    let objsOrdenados = [];
    orden.forEach(elem1 => {
      props.user.medical.schedules.forEach(elem2 => {
        if (elem2.week_day.es.name === elem1) {
          objsOrdenados.push(elem2);
        }
      });
    });

    if (props.user.medical.schedules !== state.data2) {
      return { data2: objsOrdenados };
    }
    return null;
  }

  render() {
    const { user, handleSubmit } = this.props;
    const { error } = this.state;

    const onFormSubmit = data => {
      var url = "/medical/schedule-add";

      let week_day = data.day.length > 1 ? data.day : data.day[0];
      let office = user.medical.offices[data.clinic];
      let specialties = user.medical.specialties[data.specialty];
      if (
        this.state.sinceComplete.format("DD-MM-YYYY HH:mm") <
        this.state.untilComplete.format("DD-MM-YYYY HH:mm")
      ) {
        let values = {
          schedule: {
            office: office,
            specialties: [specialties],
            hours: [
              {
                start: this.state.since,
                end: this.state.until,
                limit: data.quotas,
                type_hour: "turn",
                interval: data.intervals
              }
            ]
          }
        };

        if (data.day.length > 1) {
          values.week_days = week_day;
          url = "/schedule/add-many";
          this.props.addSchedule(values, url);
        } else {
          values.schedule.week_day = week_day;
          this.props.addSchedule(values, url);
        }

        this.handleClose();
        this.props.reset();
      } else
        this.setState({
          error: "Debe ser una hora válida"
        });
    };

    return (
      <>
        <div className="card">
          <div className="card-header">
            <button
              className="btn"
              style={{
                backgroundColor: "#19769F",
                color: "#ffffff",
                float: "right"
              }}
              onClick={() => {
                if (user.medical.offices.length === 0) {
                  this.props.dispatch(
                    showModal({
                      open: true,
                      message: "Debe agregar sus consultorio primero"
                    })
                  );
                } else this.setState({ modal: true });
              }}
            >
              Agregar
            </button>
            <div className="d-flex align-items-center">
              <div className="card-title">
                <h4
                  style={{
                    fontFamily: "Poppins",
                    color: "#19769f",
                    fontWeight: "bold"
                  }}
                >
                  Horario de atención presencial
                </h4>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table
                id="add-row"
                className="display table table-striped table-hover"
              >
                <thead>
                  <tr>
                    <th>Día</th>
                    <th>Consultorio</th>
                    <th>Especialidad</th>
                    <th>Turno</th>
                    <th>Cupos</th>
                    <th>Intervalo entre cita</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data2.map((item, i) => (
                    <tr key={i}>
                      <td>{item.week_day.es.name}</td>
                      <td>{item.office.name}</td>
                      <td>
                        {item.specialties.map(item => {
                          return item.name.es;
                        })}
                      </td>
                      <td>
                        {item.hours.map((hour, a) => (
                          <div key={a}>
                            {moment(`${"2020-01-07"} ${hour.start}`).format(
                              "HH:mm a"
                            )}
                            <br />a<br />
                            {moment(`${"2020-01-07"} ${hour.end}`).format(
                              "HH:mm a"
                            )}
                            <br />
                          </div>
                        ))}
                      </td>
                      <td>
                        {item.hours.map((hour, e) => (
                          <div key={e}>
                            {hour.limit} <br />
                          </div>
                        ))}
                      </td>
                      <td>
                        {item.hours.map((hour, u) => (
                          <div key={u}>
                            {hour.interval}min <br />
                          </div>
                        ))}
                      </td>
                      <td>
                        <div className="form-button-action">
                          {/* <button
                            type="button"
                            data-target="#addRowModal"
                            data-toggle="tooltip"
                            title=""
                            className="btn btn-link btn-primary btn-lg"
                            data-original-title="Editar"
                          >
                            <i className="fa fa-edit"></i>
                          </button>*/}
                          <button
                            onClick={() => {
                              this.setState({
                                delete: true,
                                item: item
                              });
                            }}
                            className="btn btn-link btn-danger"
                          >
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Modal size="lg" show={this.state.modal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4
                style={{
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Nuevo Horario
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Elegir Consultorio</label>
                  <div className="select2-input">
                    <Field
                      name="clinic"
                      component={renderField}
                      className="form-control"
                    >
                      <option></option>
                      {user.medical &&
                        user.medical.offices.length > 0 &&
                        user.medical.offices.map((item, i) => (
                          <option key={i} value={i}>
                            {item.name}
                          </option>
                        ))}
                    </Field>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Elegir Especialidad</label>
                  <div className="select2-input">
                    <Field
                      name="specialty"
                      component={renderField}
                      className="form-control"
                    >
                      <option></option>
                      {user.medical &&
                        user.medical.specialties.length > 0 &&
                        user.medical.specialties.map((item, i) => (
                          <option key={i} value={i}>
                            {item.name.es}
                          </option>
                        ))}
                    </Field>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Elegir Día</label>
                  <div className="select2-input">
                    <Field
                      name="day"
                      className="form-control"
                      component={renderMultiselect}
                      valueField="id"
                      placeholder="Seleccione el días"
                      textField={item => item.es.name}
                      data={days}
                      messages={{
                        emptyFilter: "La búsqueda no arrojó ningun resultado",
                        emptyList: "No hay más elementos en esta lista"
                      }}
                    />
                    {/*<Field
                      name="day"
                      component={renderField}
                      className="form-control"
                    >
                      <option></option>
                      {days.map((item, i) => (
                        <option key={i} value={i}>
                          {item.es.name}
                        </option>
                      ))}
                    </Field>*/}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Turno</label>
                  <div className="row">
                    <div className="col-sm-2">
                      <label>Desde</label>
                    </div>
                    <div className="col-sm-4">
                      <TimePicker
                        name="since"
                        minuteStep={10}
                        defaultValue={moment()
                          .hour(0)
                          .minute(0)}
                        showSecond={false}
                        onChange={value => value && this.handleSince(value)}
                      />
                    </div>
                    <div className="col-sm-2">
                      <label>Hasta</label>
                    </div>
                    <div className="col-sm-4">
                      <TimePicker
                        placeholder="Hasta"
                        minuteStep={10}
                        name="until"
                        defaultValue={moment()
                          .hour(0)
                          .minute(0)}
                        showSecond={false}
                        onChange={value => value && this.handleUntil(value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      {error && <span>{error}</span>}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Cupos</label>
                  <div className="select2-input">
                    <Field
                      name="quotas"
                      component={renderField}
                      className="form-control"
                    >
                      <option></option>
                      <option value="10">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                      <option value="60">60</option>
                      <option value="70">70</option>
                      <option value="80">80</option>
                      <option value="90">90</option>
                      <option value="100">100</option>
                    </Field>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Elegir intervalos</label>
                  <div className="select2-input">
                    <Field
                      name="intervals"
                      component={renderField}
                      className="form-control"
                    >
                      <option></option>
                      <option value="15">15 min</option>
                      <option value="20">20 min</option>
                      <option value="30">30 min</option>
                      <option value="45">45 min</option>
                      <option value="60">60 min</option>
                    </Field>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                style={{ backgroundColor: "#19769F" }}
                disabled={this.state.send ? true : false}
                onClick={handleSubmit(onFormSubmit)}
                className="btn"
              >
                Guardar
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.delete}>
          <Modal.Header>
            <Modal.Title>
              <h4
                style={{
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Eliminar
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            ¿Seguro que deseas eliminar este horario?
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="btn btn-sm"
                style={{ backgroundColor: "#19769F" }}
                onClick={() => this.setState({ delete: false, item: "" })}
              >
                Cancelar
              </button>
            </div>
            <div>
              <button
                className="btn btn-sm"
                style={{ backgroundColor: "#19769F" }}
                onClick={() => {
                  this.props.deleteSchedule(this.state.item);

                  this.setState({ delete: false, item: "" });
                }}
              >
                Aceptar
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addSchedule, deleteSchedule, showModal }, dispatch);

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "AppoinmentConfig",
    validate
  })
)(AppoinmentConfig);
