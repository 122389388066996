import React, { Component } from "react";
import { Route, Switch, Redirect, Router } from "react-router-dom";
import Main from "./Main";
import Login from "./login";
import LostPassword from "./login/LostPassword";
import InsertCode from "./login/InsertCode";
import NewPassword from "./login/NewPassword";
import Profile from "../components/sidebar/profile/profile";
import EditProfile from "../components/sidebar/profile/editprofile";
import Configuration from "../components/sidebar/profile/configuration";
import AppointmentConfig from "../components/sidebar/profile/appoinmentconfig";
import SignUp from "../components/login/SignUp";
import DoctorAppointment from "../components/sidebar/maintainer/DoctorAppointment";
import NewAppointment from "../components/sidebar/maintainer/NewAppointment";
import AllAppointment from "../components/sidebar/maintainer/AllAppointment";
import DoctorConsultation from "../components/consultation/DoctorConsultation";
import PatientEntry from "./consultation/PatientEntry";
import PatientEdit from "./consultation/PatientEntry";
import Patient from "./Patient/Patient";
import Subscription from "./Subscription";
import Transaction from "./transaction";
import DetailEMD from "./Patient/DetailEMD";
import DocumentReceived from "./documents/DocumentReceived";
import Container from "./Container";
import PatientEntryOdontology from "./consultation/PatientEntryOdontology";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { refreshToken } from "../actions/user_actions";
import Loading from "./modals/Modal_Loading";
import Alert from "./modals/Modal_Alert";
import history from "../store/browserHistory";

class AppView extends Component {
  componentDidMount() {
    this.props.refreshToken();
  }

  render() {
    return (
      <>
        <Router history={history}>
          <Switch>
            <Route exact path="/" render={() => <Login />} />
            <Route exact path="/SignUp" component={SignUp} />
            <Route exact path="/LostPassword" component={LostPassword} />
            <Route exact path="/newpassword" component={NewPassword} />
            <Route exact path="/InsertCode" component={InsertCode} />

            <Container>
              <Route exact path="/dashboard" component={Main} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/editprofile" component={EditProfile} />
              <Route exact path="/configuration" component={Configuration} />
              <Route
                exact
                path="/appointmentconfig"
                component={AppointmentConfig}
              />
              <Route
                exact
                path="/doctorappointment"
                component={DoctorAppointment}
              />
              <Route exact path="/newappointment" component={NewAppointment} />
              <Route exact path="/allappointment" component={AllAppointment} />
              <Route
                exact
                path="/doctorconsultation"
                component={DoctorConsultation}
              />
              <Route exact path="/patiententry" component={PatientEntry} />
              <Route exact path="/patient-entry-odontology" component={PatientEntryOdontology} />
              {/* <Route exact path="/patientedit" component={PatientEdit} /> */}
              <Route exact path="/patient" component={Patient} />
              <Route exact path="/subscription" component={Subscription} />
              <Route exact path="/transaction" component={Transaction} />
              <Route exact path="/detailemd" component={DetailEMD} />
              <Route
                exact
                path="/documentreceived"
                component={DocumentReceived}
              />
            </Container>

            <Redirect from="*" to="/" />
          </Switch>
        </Router>
        <Loading />
        <Alert />
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ refreshToken }, dispatch);

export default connect(null, mapDispatchToProps)(AppView);
