import * as apiNovemApp from "../api/user/api_user";

export const TRANSTATION_LIST = "TRANSTATION_LIST";

export const getTransaction = data => {
  return { type: TRANSTATION_LIST, data };
};

export const transactionAll = () => {
  let url = "/transaction/all";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    //console.log("entre en transaction", token);
    const request = apiNovemApp.requestToken(url, token);

    request.then(result => {
      //console.log("result", result);
      try {
        if (result.status === 200) {
          //console.log("entre en transaction all ");
          dispatch(getTransaction(result.data));
        } else {
        }
      } catch (e) {
        // console.log("error servidor", e);
      }
    });
  };
};
