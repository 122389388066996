import React, { Component } from "react";
import { connect } from "react-redux";

import SideBar from "../sidebar/Sidebar";
import Header from "../Header";
import UserGreet from "../../consts/UserGreet";
import SessionId from "./SessionId";

class DocumentReceived extends Component {
  render() {
    return (
      <>
        <Header />
        <SideBar />
        <div className="main-panel">
          <div className="content">
            <UserGreet />
            <div className="page-inner">
              <SessionId />
              <div className="row">
                <div className="col-md-4">
                  <div className="card card-secondary">
                    <div className="card-body skew-shadow">
                      <h1>
                        <i className="flaticon-success"></i>
                      </h1>
                      <h5 className="op-8">Profesión Principal</h5>
                      <div className="pull-right">
                        <h3 className="fw-bold op-8">88%</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card card-dark bg-secondary-gradient">
                    <div className="card-body bubble-shadow">
                      <h1>
                        <i className="flaticon-success"></i>
                      </h1>
                      <h5 className="op-8">Especialidad Principal</h5>
                      <div className="pull-right">
                        <h3 className="fw-bold op-8">88%</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card card-dark bg-secondary2">
                    <div className="card-body curves-shadow">
                      <h1>
                        <i className="flaticon-success"></i>
                      </h1>
                      <h5 className="op-8">Especialidad Principal</h5>
                      <div className="pull-right">
                        <h3 className="fw-bold op-8">88%</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps)(DocumentReceived);
