export const validate = values => {
  const errors = {};
  var reg = /^[ A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ.-]+/;
  if (!values.reference) {
    errors.reference = "Obligatorio";
  } else if (
    !/[0-9]{1,8}/i.test(values.reference) ||
    values.reference < 0 ||
    values.reference.length <= 3
  ) {
    errors.reference = "Referencia no válida";
  }

  if (!values.birthdate) {
    errors.birthdate = "Obligatorio";
  }

  if (!values.amount) {
    errors.amount = "Obligatorio";
  } else if (
    !/[0-9.]{1,8}/i.test(values.amount) ||
    values.amount < 0 ||
    values.amount.length <= 3
  ) {
    errors.amount = "Monto no válido";
  }

  if (!values.bank_id) {
    errors.bank_id = "Obligatorio";
  }

  if (!values.name) {
    errors.name = "Obligatorio";
  } else if (values.name.length > 10) {
    errors.name = "Debe tener 10 caracteres o menos";
  } else if (values.name.length < 3) {
    errors.name = "Debe tener 3 caracteres o mas";
  } else if (!reg.test(values.name)) {
    errors.name = "Caracteres no validos";
  }

  if (!values.date) {
    errors.date = "Obligatorio";
  }

  if (!values.lastname) {
    errors.lastname = "Obligatorio";
  } else if (values.lastname.length > 15) {
    errors.lastname = "Debe tener 15 caracteres o menos";
  } else if (values.lastname.length < 3) {
    errors.lastname = "Debe tener 3 caracteres o mas";
  } else if (!reg.test(values.lastname)) {
    errors.name = "Caracteres no validos";
  }

  if (parseInt(values.phone && values.phone.charAt(0)) === 0) {
    errors.phone = "El 0 no esta permitido al inicio del número";
  } else if (values.phone && !/^([0-9])*$/.test(values.phone)) {
    errors.phone = "Debe ser un número";
  }

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Dirección de correo electrónico no válida";
  }

  if (!values.dni) {
    errors.dni = "Obligatorio y debe ser solo números";
  } else if (values.dni.length > 10) {
    errors.dni = "Debe tener máximo 10 caracteres";
  } else if (values.dni.length < 1) {
    errors.dni = "Debe tener mínimo 4 caracteres";
  }
  return errors;
};
