import * as apiNovemApp from "../api/user/api_user";
import { showModal, loading, getNotification } from "./user_actions";
import history from "../store/browserHistory";

export const INIT_SPECIALTY = "INIT_SPECIALTY";
export const ADD_SPECIALTY = "ADD_SPECIALTY";
export const ADD_OFFICE = "ADD_OFFICE";
export const ADD_SCHEDULE = "ADD_SCHEDULE";
export const ADD_NON_WORKING_DAY = "ADD_NON_WORKING_DAY";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const ADD_NUMBER_DOCTOR = "ADD_NUMBER_DOCTOR";
export const ADD_EMD_TYPE = "ADD_EMD_TYPE";
export const ADD_INSURANCE = "ADD_INSURANCE";
export const FAILURE = "FAILURE";
export const DATA_APPOINTMENT = "DATA_APPOINTMENT";
export const CALENDAR_VALUES = "CALENDAR_VALUES";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_SPECIALTY = "DELETE_SPECIALTY";
export const DELETE_NON_WORKING_DAY = "DELETE_NON_WORKING_DAY";
export const DELETE_SCHEDULE = "DELETE_SCHEDULE";
export const DELETE_OFFICE = "DELETE_OFFICE";

let msgError =
  "Se ha producido un error inesperado. inténtalo de nuevo más tarde.";

export const getDataAppointment = data => {
  return { type: DATA_APPOINTMENT, data };
};

// Specialty

export const addSpecialty = data => {
  let url = "/medical/specialty-add";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loading(true));
    request.then(result => {
      dispatch(loading(false));

      try {
        if (result.status === 200) {
          dispatch(
            getNotification({
              data: `Su especialidad ${data.specialty.name.es} será verificada en aproximadamente 72 horas.`
            })
          );
          dispatch(
            showModal({
              open: true,
              message: "Especialidad agregada correctamente"
            })
          );

          dispatch({ type: ADD_SPECIALTY, data: result.data });
          history.push("/editprofile");
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const deleteSpecialty = data => {
  let url = "/specialty/delete";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(
      {
        specialty_id: data.name._id
      },
      url,
      token
    );
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch(
            showModal({
              open: true,
              message: "Especialidad eliminada correctamente"
            })
          );
          dispatch({ type: DELETE_SPECIALTY, data: data });
          history.push("/editprofile");
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const initSpecialty = () => {
  let url = "/medical/specialty-init";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToListAppointment(null, url, token);

    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch({ type: INIT_SPECIALTY, data: result.data.Specialties });
        } else {
          alert(result.msg);
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

// Office

export const addOffice = data => {
  let url = "/medical/office-add";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch(
            getNotification({
              data: `Su consultorio ${data.office.name} será verificada en aproximadamente 72 horas.`
            })
          );
          dispatch(
            showModal({ open: true, message: "Oficina agregada correctamente" })
          );
          dispatch({ type: ADD_OFFICE, data: result.data });
          history.push("/configuration");
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const deleteOffice = data => {
  let url = "/office/delete";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(
      {
        office_id: data._id
      },
      url,
      token
    );
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch(
            showModal({
              open: true,
              message: "Oficina eliminada correctamente"
            })
          );
          dispatch({ type: DELETE_OFFICE, data: data });
          history.push("/configuration");
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

// Add Insurance

export const addInsurance = data => {
  let url = "/medical/insurance-add";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        dispatch(
          showModal({ open: true, message: "Seguro agregado correctamente" })
        );

        dispatch({ type: ADD_INSURANCE, data: result.data });
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

// Add Schedule

export const addSchedule = (data, url) => {
  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch(
            showModal({ open: true, message: "Horario agregado correctamente" })
          );

          if (url === "/schedule/add-many") {
            result.data.success.map(item => {
              dispatch({ type: ADD_SCHEDULE, data: item });
              return item;
            });
          } else {
            dispatch({ type: ADD_SCHEDULE, data: result.data });
          }

          history.push("/configuration");
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const deleteSchedule = data => {
  let url = "/schedule/delete";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(
      {
        schedule_id: data._id
      },
      url,
      token
    );
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch(
            showModal({
              open: true,
              message: "Horario eliminada correctamente"
            })
          );
          dispatch({ type: DELETE_SCHEDULE, data: data });
          history.push("/configuration");
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

var Xmas95 = new Date();
var monthDate = Xmas95.getMonth() + 1;
var yearDate = Xmas95.getFullYear();

export const addNonworkingday = data => {
  let url = "/medical/vacation-add";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch(
            showModal({
              open: true,
              message: "Período no laborable agregado correctamente"
            })
          );
          dispatch(
            getCalendar({
              year: String(yearDate),
              month: String(monthDate)
            })
          );

          dispatch({ type: ADD_NON_WORKING_DAY, data: result.data });
          history.push("/configuration");
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const deleteNonworkingday = data => {
  let url = "/vacation/delete";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(
      {
        vacation_id: data._id
      },
      url,
      token
    );
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch(
            showModal({
              open: true,
              message: "Período no laborable eliminado correctamente"
            })
          );
          dispatch({ type: DELETE_NON_WORKING_DAY, data: data });
          dispatch(
            getCalendar({
              year: String(yearDate),
              month: String(monthDate)
            })
          );
          history.push("/configuration");
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const addDocument = data => {
  let url = "/medical/document-add";
  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let type = getState().reducer.typeDocuments;

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          const test = type.find(
            element => element.name === data.document.grade
          );
          var i = type.indexOf(test);
          type.splice(i, 1);
          dispatch(
            getNotification({
              data: `Su profesión ${data.document.title} será verificada en aproximadamente 72 horas.`
            })
          );
          dispatch(
            showModal({
              open: true,
              message: "Datos profesionales agregado correctamente"
            })
          );

          dispatch({ type: ADD_DOCUMENT, data: result.data });
          history.push("/editprofile");
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const deleteDocument = data => {
  let url = "/document/delete";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let type = getState().reducer.typeDocuments;

    const request = apiNovemApp.requestToUserProfession(
      {
        document_id: data._id
      },
      url,
      token
    );
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          type.push({ name: data.grade });
          dispatch(
            showModal({
              open: true,
              message: "Datos profesionales eliminado correctamente"
            })
          );

          dispatch({ type: DELETE_DOCUMENT, data: data });
          history.push("/editprofile");
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const addNumberDoctor = (data, url, type) => {
  /*  let url = "/medical/medical-number-add";*/

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch({ type: ADD_NUMBER_DOCTOR, data: result.data, item: type });
          dispatch(
            showModal({ open: true, message: "Datos agregados correctamente" })
          );
        } else {
          alert(result.msg);
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const addEmdType = (data, url, type) => {
  // url = "/medical/emd-type-add";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch({ type: ADD_EMD_TYPE, data: result.data});
          dispatch(
            showModal({ open: true, message: "Datos agregados correctamente" })
          );
        } else {
          alert(result.msg);
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};


export const getCalendar = data => {
  let url = "/calendar/get";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    const request = apiNovemApp.requestToUserProfession(data, url, token);

    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch({ type: CALENDAR_VALUES, data: result.data });
        } else {
          //console.log("Error", result);
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};
