import React, { Component } from "react";
import User from "../User";
// import Documents from '../maintainer/Documents'
import MainMenu from "../maintainer/MainMenu";
import Appointment from "../maintainer/Appointment";
import Consultation from "../maintainer/Consultation";
import Patient from "./Patient";
import { Link } from "react-router-dom";
//import Help from "./Help";


class Maintainer extends Component {
  addingClass() {
    document.getElementById("divSetting").className += " active";
  }

  render() {
    return (
      <div style={{ overflowX: "auto" }}>
        <User />
        <MainMenu />
        {/* <Documents />}*/}
        <Appointment />
        <Consultation />
        <Patient />
        <div data-tut="reactour__action">
          <ul className="nav nav-primary">
            <li className="nav-item submenu" id="divSetting">
              <a
                data-toggle="collapse"
                href="#settings"
                className="collapsed"
                aria-expanded="false"
                onClick={this.addingClass.bind(this)}
              >
                <i className="fas fa-users"></i>
                <p>Suscripciones</p>
                <span className="caret"></span>
              </a>

              <div className="collapse" id="settings">
                <ul className="nav nav-collapse">
                  <li>
                    <Link to="/subscription">
                      <span className="sub-item">Paquete</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/transaction">
                      <span className="sub-item">Transacciones</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        {/* <Help />*/}
      </div>
    );
  }
}

export default Maintainer;
