import * as apiNovemApp from "../api/user/api_user";
import history from "../store/browserHistory";

export const PATHOLOGY_SAVE = "PATHOLOGY_SAVE";
export const PATHOLOGY_LOADING = "PATHOLOGY_LOADING";
export const PATHOLOGY_DELETE = "PATHOLOGY_DELETE";
export const PATHOLOGY_VERIFICATE = "PATHOLOGY_VERIFICATE";

export const ALLERGY_SAVE = "ALLERGY_SAVE";
export const ALLERGY_LOADING = "ALLERGY_LOADING";
export const ALLERGY_DELETE = "ALLERGY_DELETE";
export const ALLERGY_VERIFICATE = "ALLERGY_VERIFICATE";

export const TOOTH_SAVE = "TOOTH_SAVE";
export const TOOTH_LOADING = "TOOTH_LOADING";
export const TOOTH_DELETE = "TOOTH_DELETE";
export const TOOTH_VERIFICATE = "TOOTH_VERIFICATE";

export const SURGICAL = "SURGICAL";
export const SURGICAL_LOADING = "SURGICAL_LOADING";
export const HABIT = "HABIT";
export const HABIT_LOADING = "HABIT_LOADING";
export const TOOTH = "TOOTH";
export const FAMILY = "FAMILY";
export const FAMILY_LOADING = "FAMILY_LOADING";
export const IMC = "IMC";
export const IMC_LOADING = "IMC_LOADING";
export const BLOOD_PRESSURE = "BLOOD_PRESSURE";
export const BLOOD_PRESSURE_LOADING = "BLOOD_PRESSURE_LOADING";
export const ADRESS = "ADRESS";
export const ADRESS_LOADING = "ADRESS_LOADING";

export const BIRTHDATE = "BIRTHDATE";
export const BIRTHDATE_LOADING = "BIRTHDATE_LOADING";

export const loadingPathology = () => {
  return { type: PATHOLOGY_LOADING };
};

export const loadingAllergy = () => {
  return { type: ALLERGY_LOADING };
};

export const loadingTooth = () => {
  return { type: TOOTH_LOADING };
};

export const loadingSurgical = () => {
  return { type: SURGICAL_LOADING };
};

export const loadingHabit = () => {
  return { type: HABIT_LOADING };
};

export const loadingFamily = () => {
  return { type: FAMILY_LOADING };
};

export const loadingImc = () => {
  return { type: IMC_LOADING };
};

export const loadingBloodPressure = () => {
  return { type: BLOOD_PRESSURE_LOADING };
};

export const loadingAdress = () => {
  return { type: ADRESS_LOADING };
};
export const loadingBirthdate = () => {
  return { type: BIRTHDATE_LOADING };
};

const getEMDType = (getState) => {

  let emdType = undefined;

  let appointments = getState().reducer.patients
  let appointmentId = getState().reducer.patient.appointment_id
  for (let appointment of appointments) {

    for (let appointmentID of appointment.appointments) {
      if (appointmentID._id === appointmentId) {
        emdType = appointmentID.patients.emd_type
        break
      }
    }
    if (emdType !== undefined) {
      break
    }
  }

  return emdType
}

export const updateAddressUser = (values) => {
  let url = "/user/update-address";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;

    const request = apiNovemApp.requestToUserProfession(values, url, token);
    dispatch(loadingAdress());

    const emdType =  getEMDType(getState)

    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({ type: BIRTHDATE, data: result.data });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        }
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const updateBirthdateUser = (values) => {
  let url = "/user/update-birthdate";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;

    const emdType = getEMDType(getState)

    const request = apiNovemApp.requestToUserProfession(values, url, token);
    dispatch(loadingBirthdate());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({ type: BIRTHDATE, data: result.data });

          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        }
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const savePathology = (values) => {
  let url = "/pathology-history/save";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let term = getState().reducer.termValue;
    let show = getState().reducer.showNot;
    let data = {
      user_id: values.user,
      PathologyHistory: {
        MedicalHistory: {
          history: {
            _id: "",
            name: {
              es: term,
              en: "",
            },
            description: {
              es: "",
              en: "",
            },
            type: "history",
            completed: false,
          },
          date: values.date,
        },
      },
    };


    let emdType = getEMDType(getState)

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingPathology());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({ type: PATHOLOGY_SAVE, data: result.data });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } else {
        }
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const deletePathology = (values, user_id) => {
  let url = "/pathology-history/delete";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: user_id,
      PathologyHistory: {
        MedicalHistory: {
          history: {
            _id: values.MedicalHistory.history._id,
          },
        },
      },
    };

    const emdType = getEMDType(getState)

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingPathology());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({ type: PATHOLOGY_DELETE, data: result.data });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } else {
        }
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const verificatePathology = (values, user_id) => {
  let url = "/pathology-history/verificate";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: user_id,
      PathologyHistory: {
        MedicalHistory: {
          history: {
            _id: values.MedicalHistory.history._id,
          },
        },
      },
    };

    let emdType = getEMDType(getState)

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingPathology());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({
            type: PATHOLOGY_VERIFICATE,
            data: result.data,
            item: values,
          });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } 
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const saveTooth = (values) => {
  let url = "/tooth/save";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: values.user,
      ...values.tooth
    };
    let emdType = getEMDType(getState)
    
    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingTooth());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({
            type: TOOTH_SAVE,
            data: result.data,
            name: "save",
          });
          
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } 
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const deleteTooth = (values, user_id) => {
  let url = "/tooth/delete";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: user_id,
      ...values.tooth
    };

    const emdType = getEMDType(getState)

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingTooth());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({ type: TOOTH_DELETE, data: result.data });
          emdType === "odontology"
            ? history.replace("/patient-entry-odontology")
            : history.push("/patiententry")
        } else {
        }
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const verificateTooth = (values, user_id) => {
  let url = "/tooth/verificate";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: user_id,
      ...values.tooth
    };

    const emdType = getEMDType(getState)

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingTooth());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({
            type: TOOTH_VERIFICATE,
            data: result.data,
            item: values,
          });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
          
        } 
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const saveSurgical = (values) => {
  let url = "/surgical-history/save";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let term = getState().reducer.termValue;
    let show = getState().reducer.showNot;
    let data = {
      user_id: values.user,
      SurgicalHistory: {
        MedicalHistory: {
          history: {
            _id: "",
            name: {
              es: term,
              en: "",
            },
            description: {
              es: "",
              en: "",
            },
            type: "history",
            completed: false,
          },
          date: values.date,
        },
        Medicines: [],
      },
    };

    const emdType = getEMDType(getState)

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingSurgical());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({
            type: SURGICAL,
            data: result.data,
            name: "save",
          });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } else {
        }
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const verificateOrDeleteSurgical = (values, user_id, url, name) => {
  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: user_id,
      SurgicalHistory: {
        MedicalHistory: {
          history: {
            _id: values.MedicalHistory.history._id,
          },
        },
      },
    };

    const emdType = getEMDType (getState)

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingSurgical());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({
            type: SURGICAL,
            data: result.data,
            item: values,
            name: name,
          });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } 
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const saveBloodPressure = (values) => {
  let url = "/medical-measurement/add-blood-pressure";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: values.user,
      blood_pressure: {
        high: {
          value: values.high,
        },
        low: {
          value: values.low,
        },
      },
    };

    const emdType = getEMDType(getState)

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingBloodPressure());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({ type: BLOOD_PRESSURE, data: result.data, name: "save" });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } else {
        }
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const verificateOrDeleteBloodPressure = (values, user_id, url, name) => {
  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: user_id,
      blood_pressure_id: values._id,
    };

    const emdType = getEMDType(getState)

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingBloodPressure());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({
            type: BLOOD_PRESSURE,
            data: result.data,
            name: name,
            item: values,
          });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } else {
        }
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const saveIMC = (values, user_id) => {
  let url = "/medical-measurement/calculate-imc";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: values.user,
      IMC: {
        weight: values.weight,
        height: values.height,
      },
    };

    const emdType = getEMDType(getState)

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingImc());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({
            type: IMC,
            data: result.data.MedicalMeasurement,
            name: "save",
          });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } 
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const verificateOrDeleteIMC = (values, user_id, position, url, name) => {
  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: user_id,
      IMC: {
        created_at: values.date,
      },
    };

    const emdType = getEMDType(getState)

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingImc());
    request.then((result) => {
      try {
        if (result.status === 200) {
          //dispatch({ type: IMC, data: result.data });
          dispatch({
            type: IMC,
            data: result.data,
            item: values,
            position: position,
            name: name,
          });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } 
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const saveAllergy = (values) => {
  let url = "/alergy/save-alergy";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: values.user,
      Alergy: {
        _id: "",
        name: {
          es: values.allergy,
          en: "",
        },
        description: {
          es: values.allergy,
          en: "",
        },
        type: "alergy",
        completed: true,
      },
    };

    const emdType = getEMDType(getState)

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingAllergy());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({ type: ALLERGY_SAVE, data: result.data });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } 
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const saveYearAllergy = (values) => {
  let url = "/alergy/save-year-test";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    const emdType = getEMDType(getState)
    const request = apiNovemApp.requestToUserProfession(values, url, token);
    dispatch(loadingAllergy());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({
            type: ALLERGY_SAVE,
            /*data: result.data,*/
            year: values.year,
          });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } 
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const deleteAllergy = (values, user_id) => {
  let url = "/alergy/delete-alergy";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: user_id,
      Alergy: {
        _id: values._id,
      },
    };
    const emdType = getEMDType(getState)
    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingAllergy());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({ type: ALLERGY_DELETE, data: result.data });
          (emdType === "odontology")
            ? history.replace("/patient-entry-odontology")
            : history.push("/patiententry")
        } 
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const verificateAllergy = (values, user_id) => {
  let url = "/alergy/verificate-alergy";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: user_id,
      Alergy: {
        _id: values._id,
      },
    };
    const emdType = getEMDType(getState)
    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingAllergy());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({
            type: ALLERGY_VERIFICATE,
            data: result.data,
            item: values,
          });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } 
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const saveHabit = (values) => {
  let url = "/habit/save";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    const emdType = getEMDType(getState)
    const request = apiNovemApp.requestToUserProfession(values, url, token);
    dispatch(loadingHabit());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({
            type: HABIT,
            data: result.data,
            name: "save",
          });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } 
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const verificateOrDeleteHabit = (values, user_id, url, name) => {
  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: user_id,
      Habit: {
        name: values.name,
      },
    };
    const emdType = getEMDType(getState)
    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingHabit());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({
            type: HABIT,
            data: result.data,
            item: values,
            name: name,
          });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } 
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const saveFamily = (values) => {
  let url = "/family-history/save";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let term = getState().reducer.termValue;
    let show = getState().reducer.showNot;
    let data = {
      user_id: values.user,
      FamilyHistory: {
        MedicalHistory: {
          history: {
            _id: "",
            name: {
              es: term,
              en: "",
            },
            description: {
              es: "",
              en: "",
            },
            type: "history",
            completed: false,
          },
          date: values.date,
        },
        is_death: values.is_death,
        familyRelationship: values.familyRelationship,
      },
    };
    const emdType = getEMDType(getState)
    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingFamily());
    request.then((result) => {

      try {
        if (result.status === 200) {
          dispatch({
            type: FAMILY,
            data: result.data,
            name: "save",
          });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } 
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};

export const verificateOrDeleteFamily = (values, user_id, url, name) => {
  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    let show = getState().reducer.showNot;
    let data = {
      user_id: user_id,
      FamilyHistory: {
        MedicalHistory: {
          history: {
            _id: values.MedicalHistory.history._id,
          },
        },
        familyRelationship: values.familyRelationship,
      },
    };
    const emdType = getEMDType(getState)
    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loadingFamily());
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch({
            type: FAMILY,
            data: result.data,
            item: values,
            name: name,
          });
          history.location.pathname === "/detailemd"
            ? history.push("/detailemd")
            : (emdType === "odontology")
              ? history.replace("/patient-entry-odontology")
              : history.push("/patiententry")
        } 
      } catch (e) {
        // console.log(msgError);
      }
    });
  };
};
