import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FormSecurity from "./form";
import { bindActionCreators } from "redux";
import FormSecurityCreate from "./SecurityCreate";
import {
  listConfirmed,
  listReserved,
  listPatients
} from "../../actions/appointment_actions";

/*let suscription = [
  {
    information: {
      name: "Kit Básico",
      description: ""
    },
    appointments: {
      unlimited: true,
      Schedule: false
    },
    offices: {
      configuration: 1,
      certification: false,
      consultation: true
    },
    patients: {
      unlimited: false,
      medical_record: 499,
      medicplus: {
        appointment_request: false,
        medical_headline: false
      }
    },
    soport: {
      email: false,
      phone: false,
      backup: false
    },
    magazine: {
      access: false
    },
    cost: {
      value: 0,
      unit: "dolar"
    }
  },
  {
    information: {
      name: "Kit Prop",
      description: ""
    },
    appointments: {
      unlimited: true,
      Schedule: false
    },
    offices: {
      configuration: 1,
      certification: false,
      consultation: true
    },
    patients: {
      unlimited: false,
      medical_record: 1500,
      medicplus: {
        appointment_request: false,
        medical_headline: false
      }
    },
    soport: {
      email: false,
      phone: false,
      backup: false
    },
    magazine: {
      access: false
    },
    cost: {
      value: 9.99,
      unit: "dolar"
    }
  },
  {
    information: {
      name: "Kit Novem",
      description: ""
    },
    appointments: {
      unlimited: true,
      Schedule: false
    },
    offices: {
      configuration: 3,
      certification: true,
      consultation: true
    },
    patients: {
      unlimited: true,
      medical_record: false,
      medicplus: {
        appointment_request: true,
        medical_headline: true
      }
    },
    soport: {
      email: true,
      phone: true,
      backup: true
    },
    magazine: {
      access: false
    },
    cost: {
      value: 99.99,
      unit: "dolar"
    }
  }
];*/

const PrivateRoute = ({
  token,
  children,
  title,
  sendValidation,
  listPatients,
  listReserved,
  listConfirmed
}) => {
  const [state, setstate] = useState(false);
  const [securityType, setsecurityType] = useState(false);
  useEffect(() => {
    if (token) {
      setstate(true);
      listReserved();
      listConfirmed();
      listPatients();
    }
  }, [listConfirmed, listPatients, listReserved, token]);

  const outputEvent = event => {
    setsecurityType(event);
  };

  return (
    <div className="page-inner">
      <h4
        className="page-title"
        style={{
          fontFamily: "Poppins",
          color: "#19769f",
          fontWeight: "bold",
          textAlign: "center"
        }}
      >
        {title}
      </h4>
      <div className="col-md-12 justify-content-center" id="tab1">
        {state ? (
          children
        ) : !securityType ? (
          <FormSecurity clickHandler={outputEvent} />
        ) : (
          <FormSecurityCreate clickHandler={outputEvent} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.reducer.tokenSecurity
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ listConfirmed, listReserved, listPatients }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
