import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { Field, reduxForm, initialize as initializeForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMd } from "@fortawesome/free-solid-svg-icons";
import { addNumberDoctor, addInsurance, addEmdType } from "../../../actions/doctor_actions";
import {
  editUser,
  updatePicture,
  getPhoto
} from "../../../actions/user_actions";
import axios from "axios";

class PersonalData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: this.props.user.user.picture
    };
    this.onChange = this.onChange.bind(this);
    this.getImage();
  }

  onChange(e) {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.setState({
        files: reader.result
      });
      this.props.dispatch(getPhoto(this.state.files));
    };
  }

  componentDidMount() {
    const { user } = this.props;

    const initData = {
      name: user.user.name,
      lastname: user.user.lastname,
      birthdate: user.user.birthdate,
      gender: user.user.gender,
      city: user.user.city,
      alias: user.user.alias,
      country: user.user.country,
      address: user.user.address,
      number: user.medical.medical_number,
      ministry: user.medical.governmental_number,
      emdType: user.medical.emd_type,
      insurance:
        user.medical.insurances &&
        user.medical.insurances.length > 0 &&
        user.medical.insurances[0]._id
    };
    this.props.initializeForm("PersonalData", initData);
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (user !== prevProps.user) {
      this.setState({
        files: prevProps.user.user.picture
      });
    }
  }

  getImage() {
    axios
      .get(this.props.user.user.picture)
      .then(result => {
        this.setState({ files: this.props.user.user.picture });
      })
      .catch(error => {
        this.setState({ files: this.props.user.user.picture });
      });
  }

  render() {
    const { user, history, handleSubmit, listInsurance } = this.props;

    const onFormSubmit = data => {
      if (data.ministry !== user.medical.governmental_number) {
        let url = "/medical/governmental-number-add";
        this.props.dispatch(
          addNumberDoctor(
            {
              governmental_number: data.ministry
            },
            url,
            true
          )
        );
      }
      if (data.emdType !== user.medical.emd_type) {
        let url = "/medical/emd-type-add";
        this.props.dispatch(
          addEmdType(
            {
              emd_type: data.emdType
            },
            url,
            true
          )
        );
      }
      if (data.number !== user.medical.medical_number) {
        let url = "/medical/medical-number-add";
        this.props.dispatch(
          addNumberDoctor(
            {
              medical_number: data.number
            },
            url,
            false
          )
        );
      }
      if (this.state.files !== user.user.picture) {
        let picture;
        picture = this.state.files.slice(22, this.state.files.length);

        this.props.dispatch(updatePicture({ picture: picture }, history));
      }

      if (
        user.user.name !== data.name ||
        user.user.lastname !== data.lastname ||
        user.user.birthdate !== data.birthdate ||
        user.user.gender !== data.gender ||
        user.user.city !== data.city ||
        user.user.alias !== data.alias ||
        user.user.country !== data.country ||
        user.user.address !== data.address
      ) {
        let values = {
          EditData: {
            name: data.name,
            lastname: data.lastname,
            country: data.country,
            city: data.city,
            birthdate: data.birthdate,
            alias: data.alias,
            address: data.address,
            gender: data.gender
          }
        };

        this.props.dispatch(editUser(values));
      }

      if (
        data.insurance &&
        data.insurance !== "Ninguno" &&
        data.insurance !==
          (user.medical.insurances.length > 0 && user.medical.insurances[0]._id)
      ) {
        let insurance = listInsurance.insurances_list[0][data.insurance - 1];
        let values = {
          insurance: {
            _id: String(insurance._id),
            name: insurance.name,
            country: insurance.country
          }
        };

        this.props.dispatch(addInsurance(values));
      }
    };

    return (
      <div className="card">
        <div className="card-header">
          <h4
            style={{
              fontFamily: "Poppins",
              color: "#19769f",
              fontWeight: "bold"
            }}
          >
            Datos personales
          </h4>
        </div>
        <div className="card-body">
          <div className="row mt-3">
            <div
              className="input-file input-file-image"
              style={{ marginLeft: 15 }}
            >
              {this.state.files === "" ? (
                <FontAwesomeIcon icon={faUserMd} size="6x" />
              ) : (
                <img
                  className="img-upload-preview img-circle"
                  width="100"
                  height="100"
                  src={this.state.files}
                  alt="preview"
                />
              )}
            </div>

            <div
              className="input-file input-file-image"
              style={{ marginTop: 35, marginLeft: 20 }}
            >
              <input
                type="file"
                className="form-control form-control-file"
                id="uploadImg1"
                name="uploadImg1"
                accept="image/*"
                required=""
                onChange={this.onChange}
              />
              <label
                htmlFor="uploadImg1"
                className=" label-input-file btn btn-default btn-round"
              >
                <span className="btn-label">
                  <i className="fa fa-file-image"></i>
                </span>{" "}
                Actualizar Imagen
              </label>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="form-group">
                <label>Nombre</label>
                <Field
                  name="name"
                  className="form-control"
                  component="input"
                  type="text"
                />
              </div>
              <div className="form-group">
                <label>Género</label>
                <Field
                  name="gender"
                  component="select"
                  className="form-control"
                >
                  <option value={"Femenino"}>Femenino</option>
                  <option value={"Masculino"}>Masculino</option>
                </Field>
                <div style={{ height: 20 }}></div>
              </div>

              <div className="form-group">
                <label>Dirección</label>
                <Field
                  name="address"
                  className="form-control"
                  component="input"
                  type="text"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Apellido</label>
                <Field
                  name="lastname"
                  className="form-control"
                  component="input"
                  type="text"
                />
              </div>

              <div className="form-group">
                <label>País</label>
                <Field
                  name="country"
                  className="form-control"
                  component="input"
                  type="text"
                />
              </div>

              <div className="form-group">
                <label>Número de colegio de médicos</label>
                <Field
                  name="number"
                  className="form-control"
                  component="input"
                  type="text"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Fecha de Nacimiento</label>
                <Field
                  name="birthdate"
                  className="form-control"
                  component="input"
                  type="date"
                />
              </div>
              <div className="form-group">
                <label>Ciudad</label>
                <Field
                  name="city"
                  className="form-control"
                  component="input"
                  type="text"
                />
              </div>
              <div className="form-group">
                <label>Número de ministerio de salud</label>
                <Field
                  name="ministry"
                  className="form-control"
                  component="input"
                  type="text"
                />
              </div>
              {/*  <div className="form-group">
                <label>Seguro</label>
                <Field
                  name="insurance"
                  component="select"
                  className="form-control"
                >
                  {listInsurance &&
                    listInsurance.insurances_list &&
                    listInsurance.insurances_list[0].map((item, i) => (
                      <option key={i} value={parseInt(item._id)}>
                        {item.name}
                      </option>
                    ))}
                  <option value={"Ninguno"}>Ninguno</option>
                </Field>
                <div style={{ height: 20 }}></div>
              </div>*/}
           
            </div>
          </div>

          <div className="row mt-3">
          <div className="col-md-4">
 <div className="form-group">
                <label>Tipo de expediente a utilizar</label>
                <div className="select2-input">
                  <Field
                    name="emdType"
                    component="select"
                    className="form-control"
                  >
                    <option></option>
                    <option value={"basic"}>Medicina General</option>
                    <option value={"odontology"}>Odontológico</option>
                  </Field>
                </div>
              </div>
          </div>
              </div>

          <div className="text-right mt-3 mb-3">
            <button
              className="btn"
              style={{ backgroundColor: "#19769F" }}
              onClick={handleSubmit(onFormSubmit)}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    listInsurance: state.reducer.listInsurance
  };
};

const mapDispatchToProps = dispatch => {
  bindActionCreators(
    {
      addNumberDoctor,
      addInsurance,
      editUser,
      getPhoto,
      updatePicture /*initInsurance */
    },
    dispatch
  );
  return {
    initializeForm: (formName, values) =>
      dispatch(initializeForm(formName, values))
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "PersonalData",
    initialValues: {}
  })
)(PersonalData);
