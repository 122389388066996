import * as apiNovemApp from "../api/user/api_user";
import history from "../store/browserHistory";

import { getCalendar } from "./doctor_actions";

export const SUCCESS = "SUCCESS";
export const SUCCESSREFRESH = "SUCCESSREFRESH";
export const FAILURE = "FAILURE";
export const INIT_INSURANCE = "INIT_INSURANCE";
export const INTRO_SHOW = "INTRO_SHOW";
export const EDIT_USER = "EDIT_USER";
export const EDIT_PHOTO = "EDIT_PHOTO";
export const PHOTO = "PHOTO";
export const SHOW_MODAL = "SHOW_MODAL";
export const LOADING = "LOADING";
export const NOTIFICATION = "NOTIFICATION";
export const LIBRARY_ALL_BY_TYPE = "LIBRARY_ALL_BY_TYPE";
export const TOOTH_LIBRARY = "TOOTH_LIBRARY";
export const SECURITYLOGIN = "SECURITYLOGIN";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";
export const EMAIL_FORGET_PASSWORD = "EMAIL_FORGET_PASSWORD";
export const TOKEN_NEW_PASSWORD = "TOKEN_NEW_PASSWORD";
export const TERM_VALUE = "TERM_VALUE";
export const TOOTH_TERM_VALUE = "TOOTH_TERM_VALUE";
export const DESTROY_SESSION = "DESTROY_SESSION";
let msgError =
  "Se ha producido un error inesperado. inténtalo de nuevo más tarde.";

export const getUser = data => {
  return { type: SUCCESS, data };
};

export const getRefresh = () => {
  return { type: SUCCESSREFRESH };
};
export const getNotification = data => {
  return { type: NOTIFICATION, data };
};

export const term = data => {
  return { type: TERM_VALUE, data };
};

export const toothTerm = data => {
  return { type: TOOTH_TERM_VALUE, data };
};

export const updateLibraryAllByType = result => {
  return { type: LIBRARY_ALL_BY_TYPE, result };
};

export const updateToothLibrary = result => {
  return { type: TOOTH_LIBRARY, result };
};

export const getPhoto = data => {
  return { type: PHOTO, data };
};

export const showModal = data => {
  return { type: SHOW_MODAL, data };
};

export const loading = data => {
  return { type: LOADING, data };
};

const api = "https://novemapp.com:8080";
// const api = "https://dev.novemapp.com:8080";

export const showIntro = () => {
  let url = "/medical/intro";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToListAppointment(null, url, token);

    request.then(result => {
      try {
        if (result.status === 200) {
          //dispatch({ type: INTRO_SHOW, data: true });
        } else {
        }
      } catch (e) {}
    });
  };
};

export const initInsurance = () => {
  let url = "/insurance/init";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestData(url, api, token);

    request.then(result => {
      //console.log(result);
      try {
        if (result.status === 200) {
          dispatch({ type: INIT_INSURANCE, data: result.data.lists });
        } else {
          console.log("Error en initInsurance");
        }
      } catch (e) {
        console.log("Error en initInsurance");
      }
    });
  };
};

export const editUser = data => {
  let url = "/user/edit";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestEdit(data, url, api, token);
    dispatch(loading(true));
    request.then(result => {
      dispatch(loading(false));
      try {
        if (result.status === 200) {
          dispatch({ type: EDIT_USER, data: result.data });
          dispatch(
            showModal({
              open: true,
              message: "Usuario editado correctamente"
            })
          );
        } else {
          dispatch(
            showModal({
              open: true,
              message: result.msg
            })
          );
        }
      } catch (e) {
        console.log("Error en editUser");
      }
    });
  };
};

export const availableAppointment = values => {
  let url = "/user/available-appointment";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestEdit(values, url, api, token);

    /*request.then(result => {
      try {
        if (result.status === 200) {
          //console.log("Turn");
        } else {
          //console.log("Error");
        }
      } catch (e) {
        console.log(msgError);
      }
    });*/
    return request;
  };
};

export const updatePicture = data => {
  let url = "/user/update-picture";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestEdit(data, url, api, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch({ type: EDIT_PHOTO, data: result.data.pictureUrl });
          history.push("/editprofile");
          dispatch(
            showModal({
              open: true,
              message: result.msg
            })
          );
        } else {
          dispatch(
            showModal({
              open: true,
              message: result.msg
            })
          );
        }
      } catch (e) {
        console.log("Error en updatePicture");
      }
    });
  };
};

var Xmas95 = new Date();
var monthDate = Xmas95.getMonth() + 1;
var yearDate = Xmas95.getFullYear();

export const sendValidation = () => {
  let url = "/medical/send_validation";
  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;
    const request = apiNovemApp.requestToken(url, token);

    request.then(result => {
      try {
        if (result.status === 200) {
          //console.log("medical/send_validation", result.data);
          //history.push("/dashboard");
        } else {
        }
      } catch (e) {
        dispatch(getUser({}));
        history.push("/");
        console.log("Error en refreshToken", e);
      }
    });
  };
};
export const refreshToken = () => {
  let url = "/medical/refresh";
  return (dispatch, getState) => {
    let token = localStorage.getItem("user");

    const request = apiNovemApp.requestToken(url, token);

    request.then(result => {
      try {
        if (result.status === 200) {
          //console.log("entre en refrestf", result.data);
          localStorage.setItem("user", result.data.token.access_token);

          dispatch(getRefresh());
          dispatch(getUser(result.data));

          dispatch(initInsurance());
          dispatch(
            getCalendar({
              year: String(yearDate),
              month: String(monthDate)
            })
          );
          history.push("/dashboard");
        } else {
          localStorage.removeItem("user");
          dispatch(getUser({}));
          history.push("/");
        }
      } catch (e) {
        dispatch(getUser({}));
        localStorage.removeItem("user");
        history.push("/");
        console.log("Error en refreshToken");
      }
    });
  };
};

export const login = values => {
  let data = {
    LoginData: {
      email: values.email,
      password: values.password,
      userId: "",
      type: "3",
      token: "",
      refresh_token: ""
    }
  };
  let url = "/medical/login";

  const request = apiNovemApp.requestUser(data, url);

  return dispatch => {
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          localStorage.setItem("user", result.data.token.access_token);

          //  console.log("result.data", result.data);
          dispatch(getUser(result.data));
          dispatch(initInsurance());
          dispatch(
            getCalendar({
              year: String(yearDate),
              month: String(monthDate)
            })
          );

          history.push("/dashboard");
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
          //alert(result.msg);
          history.push("/");
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
        //alert(msgError);
      }
    });
  };
};

export const register = values => {
  let data = {
    RegisterData: {
      email: values.email,
      password: values.password,
      birthdate: values.birthdate,
      gender: values.gender,
      name: values.name,
      lastname: values.lastname,
      alias: "",
      city: "",
      address: "",
      country: "",
      pictureBase64: ""
    }
  };

  let url = "/medical/register";

  const request = apiNovemApp.requestUser(data, url);
  return dispatch => {
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          localStorage.setItem("user", result.data.token.access_token);
          dispatch(getUser(result.data));
          history.push("/dashboard");
        } else {
          alert(result.msg);
          history.push("/");
        }
      } catch (e) {
        alert(msgError);
      }
    });
  };
};

export const emailForgetPassword = email => {
  return { type: EMAIL_FORGET_PASSWORD, email };
};

export const fetchForgetPassword = (values, type) => {
  let url = "/user/forget-password";
  const request = apiNovemApp.requestPassword(values, url, api);

  return dispatch => {
    dispatch(emailForgetPassword(values.ForgetPassword.email));
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          if (!type) {
            dispatch(
              showModal({
                open: true,
                message:
                  "Se ha enviado un código a su correo para recuperar la contraseña"
              })
            );
            history.push("/InsertCode");
          }
        } else {
          if (!type) {
            dispatch(showModal({ open: true, message: result.msg }));
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
  };
};

export const fetchPasswordConfirm = (values, type) => {
  let url = "/user/forget-password-confirm";

  const request = apiNovemApp.requestPassword(values, url, api);

  return dispatch => {
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch(tokenForNewPassword(result.data.access_token));

          if (!type) {
            dispatch(showModal({ open: true, message: result.msg }));
            history.push("/newpassword");
          }
        } else {
          if (!type) {
            dispatch(showModal({ open: true, message: result.msg }));
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
  };
};

export const tokenForNewPassword = token => {
  return { type: TOKEN_NEW_PASSWORD, token };
};

export const securitySuccess = () => {
  return { type: SECURITYLOGIN };
};

export const fetchChangePassword = (data, token, type) => {
  let url = "/user/password-change";
  const request = apiNovemApp.requestEdit(data, url, api, token);

  return dispatch => {
    dispatch(loading(true));
    request.then(result => {
      try {
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch(showModal({ open: true, message: result.msg }));
          if (!type) {
            history.push("/");
          }
        } else {
          if (!type) {
            dispatch(showModal({ open: true, message: result.msg }));
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
  };
};

export const termLibraryInit = dataType => {
  return (dispatch, getState) => {
    var url = "/term-library/all-by-type";
    let token = getState().reducer.user.token.access_token;

    let type = {
      type: dataType
    };
    const request = apiNovemApp.requestEdit(type, url, api, token);
    request.then(result => {
      try {
        dispatch(updateLibraryAllByType(result.data));
      } catch (e) {
        console.log(e);
      }
    });
  };
};

export const toothLibraryInit = () => {
  return (dispatch, getState) => {
    var url = '/tooth-library/auto-complete';
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestEdit(null, url, api, token);
    request.then(result => {
      try {
        dispatch(updateToothLibrary(result));
      } catch (e) {
        console.log(e);
      }
    });
  };
};

export const securityLogin = data => {
  return (dispatch, getState) => {
    var url = "/security/login";
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    dispatch(loading(true));
    request.then(result => {
      try {
        //console.log("securitylogin", result);
        dispatch(loading(false));
        if (result.status === 200) {
          dispatch(securitySuccess());
          dispatch(getUser(result.data));
        } else {
          console.log("entro acaa");
          dispatch(
            showModal({
              open: true,
              message: result.msg
            })
          );
        }
      } catch (e) {
        console.log(e);
      }
    });
  };
};

export const securityCeate = data => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    var url = "/security/create";
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(data, url, token);
    return request.then(result => {
      
      dispatch(loading(false));
      try {
      
        dispatch(
          showModal({
            open: true,
            message: result.msg
          })
        );

        return result;
      } catch (e) {
        dispatch(
          showModal({
            open: true,
            message: result.msg
          })
        );
        console.log(e);
        return e;
      }
    });
  };
};

export const onLogout = () => {
  let url = "/medical/logout";

  return (dispatch, getState) => {
    dispatch(loading());
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToListAppointment(null, url, token);

    request.then(result => {
      try {
        
        if (result.status === 200) {
          history.push("/");
          dispatch({ type: DESTROY_SESSION });
        }
      } catch (e) {}
    });
  };
};
