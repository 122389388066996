import React, { Component } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
// import moment from "moment";
import "moment/locale/es";
import CrudButtons from "../../consts/CrudButtons";
import { saveHabit } from "../../actions/emd_actions";
import Loading from "../Loading";

const habit = [
  { label: "Fumar", value: "Fumar" },
  { label: "Alcohol", value: "Alcohol" },
  { label: "Drogas Adictivas", value: "Drogas Adictivas" },
  { label: "Antidepresivos", value: "Antidepresivos" },
  { label: "Otro", value: "Otro" }
];

const frecuency = [
  { label: "Esporádicamente", value: "Esporádicamente" },
  { label: "1 a 2 veces por día", value: "1 a 2 veces por día" },
  { label: "1 a 2 veces por semanas", value: "1 a 2 veces por semanas" },
  { label: "1 a 2 veces al mes", value: "1 a 2 veces al mes" }
];

const renderField = ({
  input,
  name,
  className,
  type,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <input className={className} {...input} type={type} />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

class Habits extends Component {
  state = {
    count: 0,
    form: false
  };

  /*componentDidUpdate() {
    this.props.change("habit", habit[0].value);
    this.props.change("frecuency", frecuency[0].value);
  }*/

  increment() {
    this.setState({ count: this.state.count + 3 });
  }

  decrement() {
    this.setState({ count: this.state.count - 3 });
  }

  render() {
    const {
      data,
      handleSubmit,
      user,
      dispatch,
      reset,
      firstValue,
      loading
    } = this.props;

    const onFormSubmit = data => {
      let type;
      if (data.frecuency === "Esporádicamente") {
        type = "Sporadically";
      }
      if (data.frecuency === "1 a 2 veces por día") {
        type = "1 to 2 times per day";
      }
      if (data.frecuency === "1 a 2 veces por semanas") {
        type = "1 a 2 veces por week";
      }
      if (data.frecuency === "1 a 2 veces al mes") {
        type = "1 a 2 times per month";
      }

      let values = {
        user_id: user._id,
        name: data.habit2
          ? data.habit2
          : data.habit
          ? data.habit
          : habit[0].value,
        Frecuency: {
          es: data.frecuency ? data.frecuency : "Esporádicamente",
          en: type ? type : "Sporadically"
        }
      };

      dispatch(saveHabit(values));
      this.setState({ form: false });
      reset("Habits");
    };

    let finit = Math.round(data && data.length / 3);
    if (!Number.isInteger(data && data.length / 3)) finit = finit + 1;

    const createTable = value => {
      let table = [];

      for (let i = 0; i < value; i++) {
        table.push(
          <li className="page-item" key={i}>
            <button
              style={{
                color: i * 3 === this.state.count ? "#ffffff" : "#19769F",
                backgroundColor: i * 3 === this.state.count ? "#19769F" : null
              }}
              className="page-link"
              onClick={() => this.setState({ count: i * 3 })}
            >
              {i + 1}
            </button>
          </li>
        );
      }
      return table;
    };

    return (
      <div className="col-md-6">
        <div className="card full-height">
          <div className="card-header">
            <div className="d-flex align-items-center">
              <div className="card-title">Hábitos</div>
              <div className="ml-auto">
                <button
                  onClick={() => {
                    this.setState({ form: !this.state.form });
                    reset("Habits");
                  }}
                  className="btn btn-icon btn-round btn-primary text-center"
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            {this.state.form && (
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <label>Nombre del habito</label>

                    {firstValue !== "Otro" && (
                      <Field
                        name={"habit"}
                        component={"select"}
                        className={"form-control"}
                      >
                        {habit.map((item, i) => (
                          <option
                            value={item.value}
                            key={i}
                            
                          >
                            {item.label}
                          </option>
                        ))}
                      </Field>
                    )}

                    {firstValue === "Otro" && (
                      <Field
                        name={"habit2"}
                        type={"text"}
                        component={renderField}
                        className={"form-control"}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label>Frecuencia</label>
                    <Field
                      name={"frecuency"}
                      component={"select"}
                      className={"form-control"}
                    >
                      {frecuency.map((item, i) => (
                        <option value={item.value} key={i}>
                          {item.label}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="form-group">
                    <label>{/*Acción*/}</label>

                    <button
                      onClick={handleSubmit(onFormSubmit)}
                      className="btn btn-icon btn-round btn-primary text-center"
                    >
                      <i className="fas fa-check"></i>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {loading && <Loading />}
            {!loading && (
              <>
                <div className="separator-dashed"></div>
                {data &&
                  data
                    .sort(function(a, b) {
                      a = new Date(a.created_at);
                      b = new Date(b.created_at);
                      if (a > b) return -1;
                      if (a < b) return 1;
                      return 0;
                    })
                    .slice(this.state.count, this.state.count + 3)
                    .map((item, i) => {
                      return (
                        <div key={i}>
                          <div className="d-flex">
                            <div className="flex-1 ml-3 pt-1">
                              <h6
                                className="text-uppercase fw-bold mb-1"
                                style={{ fontSize: ".65rem" }}
                              >
                                Nombre del hábito
                              </h6>
                              <span className="text-muted">{item.name}</span>
                              <span className="text-warning pl-3"></span>
                            </div>
                            <div className="flex-1 ml-3 pt-1">
                              <h6
                                className="text-uppercase fw-bold mb-1"
                                style={{ fontSize: ".65rem" }}
                              >
                                Frecuencia
                              </h6>
                              <span className="text-muted">
                                {item.Frecuency.es}
                              </span>
                            </div>
                            <CrudButtons
                              data={item}
                              user_id={user._id}
                              clickHandler={"habit"}
                            />
                          </div>

                          <div className="separator-dashed"></div>
                        </div>
                      );
                    })}
                <br />
                <div className="align-content-center justify-content-center">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      {this.state.count > 0 ? (
                        <button
                          style={{ color: "#19769F" }}
                          onClick={this.decrement.bind(this)}
                          className="page-link"
                        >
                          <i className="fas fa-angle-double-left"></i>
                        </button>
                      ) : (
                        false
                      )}
                    </li>
                    {createTable(finit)}
                    {this.state.count + 3 < finit * 3 ? (
                      <li className="page-item">
                        <button
                          style={{ color: "#19769F" }}
                          onClick={this.increment.bind(this)}
                          className="page-link"
                        >
                          <i className="fas fa-angle-double-right"></i>
                        </button>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector("Habits");

const mapDispatchToProps = dispatch => {
  bindActionCreators({ saveHabit }, dispatch);
};
export default compose(
  connect(state => {
    const firstValue = selector(state, "habit");
    // or together as a group
    return {
      firstValue,
      mapDispatchToProps,
      loading: state.reducer.loadingHabit
    };
  }),
  reduxForm({
    form: "Habits"
  })
)(Habits);
