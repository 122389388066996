import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import Loading from "./Loading";
//import { compose } from "redux";
import { connect } from "react-redux";
//import { withRouter } from "react-router-dom";
import { stadisticUser } from "../actions/suscription_actions";
import { bindActionCreators } from "redux";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const SuscriptionDetails = ({ user, stadisticUser, data, success }) => {
  const [dataNotice, setdataNotice] = useState({});

  useEffect(() => {
    if (
      success ||
      Object.entries(data).length !== Object.entries(dataNotice).length
    ) {

      setdataNotice(data);
    } else {

      stadisticUser();
    }
  }, [data, dataNotice, stadisticUser, success]);

  return (
    <div className="col-md-6 appointment-module" data-tut="reactour__style">
      <div className="card full-height">
        <div className="card-header">
          <div className="d-flex align-items-center">
            <div className="card-title row justify-content-between w-100 pr-2">
              <h4
                style={{
                  fontFamily: "Poppins",
                  color: "#19769f",
                  fontWeight: "bold"
                }}
              >
                Uso de suscripción
              </h4>
              <div className="row">
                <h4>PLAN: </h4>
                <h4 className="font-weight-bold ml-2">
                  {user.medical.suscription.name}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          {!success ? (
            <div className="GridLoader">
              <Loading />
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th>Item</th>
                    <th>Límite</th>
                    <th>Usado</th>
                  </tr>
                  {Object.entries(dataNotice).length > 0 &&
                    Object.keys(dataNotice).map((data, index) => {
                      if (dataNotice[data].limit) {
                        let limit = dataNotice[data].limit;

                        let name = data;

                        switch (data) {
                          case "office":
                            name = "Consultorio";
                            break;
                          case "appointment":
                            name = "Cita";
                            break;
                          default:
                            break;
                        }
                        if (limit === "unlimited") {
                          limit = "Ilimitado";
                        }

                        return (
                          <tr key={index}>
                            <td>{name}</td>
                            <td className="font-weight-600">{limit}</td>
                            <td className="font-weight-600">
                              {dataNotice[data].count}
                            </td>
                          </tr>
                        );
                      } else return null;
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    data: state.suscription.data,
    success: state.suscription.successStadistic
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ stadisticUser }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuscriptionDetails);
