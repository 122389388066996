import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addSchedule } from "../../../actions/doctor_actions";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { findByDni } from "../../../actions/appointment_actions";
import { getDataAppointment } from "../../../actions/doctor_actions";
import { availableAppointment, showModal } from "../../../actions/user_actions";

import MomentLocaleUtils from "react-day-picker/moment";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";

import "moment/locale/es";
moment.locale("es");

class AddAppoinment extends Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleClinic = this.handleClinic.bind(this);
    this.handleTurn = this.handleTurn.bind(this);
    this.state = {
      selectedDay: null,
      since: "",
      until: "",
      clinic: false,
      turn: [],
      schedules: [],
      scheduleSelect: false,
      locale: "es",
      appointment: "",
      disabledDay: [{ daysOfWeek: [0, 1, 2, 3, 4, 5, 6] }],
      day: false
    };
  }

  handleDayClick(day, { selected }) {
    this.setState({
      selectedDay: selected ? undefined : day,
      turn: [],
      scheduleSelect: [],
      day: moment(day).format("YYYY-MM-DD")
    });
    this.state.schedules.map(item => {
      if (parseInt(item.week_day._id) === moment(day).day()) {
        this.setState({
          scheduleSelect: item
        });
        this.props.dispatch(
          getDataAppointment({
            date: moment(day).format("YYYY-MM-DD"),
            schedule: item._id
          })
        );
      }

      return item;
    });
  }

  handleClinic(data) {
    const { user, calendar } = this.props;
    let disabled = [{ daysOfWeek: "" }];
    let day = [0, 1, 2, 3, 4, 5, 6];
    let schedules = [];

    user.medical.schedules.map((item, i) => {
      if (item.office._id === data) {
        day.splice(day.indexOf(parseInt(item.week_day._id)), 1);
        let test = {};
        calendar.map(date => {
          test.after = new Date(moment(date.start).format("YYYY-MM-DD"));
          test.before = new Date(date.end);
          disabled.push(test);
          return test;
        });

        disabled.push({
          after: new Date(moment().add(-40, "d")),
          before: new Date()
        });

        disabled[0].daysOfWeek = day;

        schedules.push(item);
        this.setState({
          clinic: data,
          disabledDay: disabled,
          schedules: schedules
        });
      }
      return day;
    });
  }

  handleTurn(data) {
    let test = {
      appointment: {
        date: this.state.day,
        hour_id: data,
        medical_id: this.props.user.medical.medical_presenter.medical_id,
        schedule_id: this.state.scheduleSelect._id
      }
    };

    this.props.dispatch(availableAppointment(test)).then(data => {
      if (data.status === 200) {
        let date = moment().format("YYYY-MM-DD");
        if (this.state.day === date) {
          let newArray = [];
          let date = moment().format("YYYY-MM-DD");

          for (var i = 0; i < data.data.availables.length; i++) {
            var f1 = new Date(moment()); //31 de diciembre de 2015
            var f2 = new Date(
              moment(`${date} ${data.data.availables[i]}`).format(
                "YYYY-MM-DD HH:mm"
              )
            );

            if (f1.getTime() <= f2.getTime()) {
              newArray.push(data.data.availables[i]);
            }
          }
          this.setState({
            turn: newArray.sort()
          });
        } else {
          this.setState({
            turn: data.data.availables.sort()
          });
        }
      }
    });
  }

  render() {
    const { user } = this.props;

    return (
      <div className="card">
        <div className="card-header">
          <h4
            style={{
              fontFamily: "Poppins",
              color: "#19769f",
              fontWeight: "bold"
            }}
          >
            Información de la Consulta
          </h4>
        </div>
        <div
          className="card-body"
          style={{ justifyContent: "center"}}
        >
          <div className="col-md-12 consult-info">
            <div className="form-group">
              <div className="select2-input">
                <label>Consultorio</label>
                <Field
                  name="clinic"
                  component="select"
                  className="form-control"
                  onChange={e => this.handleClinic(e.target.value)}
                >
                  <option></option>
                  {user.medical &&
                    user.medical.offices.length > 0 &&
                    user.medical.offices.map((item, i) => (
                      <option key={i} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                </Field>
              </div>
            </div>
          </div>
          <div className="row row-block">
            <div className="col-md-6">
              <div className="form-group">
                <label>Fecha</label>

                <DayPicker
                  initialMonth={new Date()}
                  month={new Date()}
                  fromMonth={new Date()}
                  localeUtils={MomentLocaleUtils}
                  locale={this.state.locale}
                  selectedDays={this.state.selectedDay}
                  onDayClick={this.handleDayClick}
                  disabledDays={this.state.disabledDay}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Turno</label>
                <Field
                  name="turn"
                  component="select"
                  className="form-control"
                  onChange={e => this.handleTurn(e.target.value)}
                  disabled={this.state.day ? false : true}
                >
                  <option></option>
                  {this.state.scheduleSelect &&
                    this.state.scheduleSelect.hours.map((data, i) => (
                      <option key={i} value={data._id}>
                        De{" "}
                        {moment(`${this.state.day} ${data.start}`).format(
                          "HH:mm a"
                        )}{" "}
                        a{" "}
                        {moment(`${this.state.day} ${data.end}`).format(
                          "HH:mm a"
                        )}
                      </option>
                    ))}
                </Field>
                <br />
                <label>Hora</label>
                <Field
                  name="hour"
                  component="select"
                  className="form-control"
                  onClick={() => { this.setState({ hour: true })}}
                  disabled={this.state.turn.length === 0 ? true : false}
                >
                  <option></option>
                  {this.state.turn.length > 0 &&
                    this.state.turn.map((data, i) => (
                      <option key={i} value={data}>
                        {moment(`${this.state.day} ${data}`).format("HH:mm a")}
                      </option>
                    ))}
                </Field>

                <br />
                <label>Motivo de Consulta</label>
                <div className="select2-input">
                  <Field
                    name="reason"
                    component="select"
                    className="form-control"
                    disabled={
                      this.state.turn.length > 0 &&
                      this.state.hour &&
                      this.state.scheduleSelect &&
                      this.state.clinic
                        ? false
                        : true
                    }
                  >
                    <option></option>
                    <option value={"Chequeo"}>Chequeo</option>
                    <option value={"Primera Cita"}>Primera Cita</option>
                    <option value={"Emergencia"}>Emergencia</option>
                    <option value={"Consulta online"}>Consulta online</option>
                  </Field>
                </div>
                
                {/* <br />
                <label>Tipo de EMD</label>
                <div className="select2-input">
                  <Field
                    name="emd_type"
                    component="select"
                    className="form-control"
                  >
                    <option></option>
                    <option value={"basic"}>Medicina General</option>
                    <option value={"odontology"}>Odontológico</option>
                  </Field>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    calendar: state.reducer.calendar
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addSchedule,
      findByDni,
      getDataAppointment,
      availableAppointment,
      showModal
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "AddAppoinment"
  })
)(AddAppoinment);
