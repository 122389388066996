import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as form } from "redux-form";

import { reducer } from "./user_reducers";
import { DESTROY_SESSION } from "../actions/user_actions";
import { suscription } from "./suscriptions_reducers";
import { transaction } from "./transaction_reducers";
const rootReducer = combineReducers({
  reducer,
  suscription,
  transaction,
  /* Router Reducer */
  routerReducer,
  /* Redux Form */
  form
});
const rootReducerFuntion = (state, action) => {
  if (action.type === DESTROY_SESSION) {
    localStorage.removeItem("user");
    state = undefined;
  }

  return rootReducer(state, action);
};

export default rootReducerFuntion
