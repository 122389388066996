import * as apiNovemApp from "../api/user/api_user";
//import history from "../store/browserHistory";
import { showModal, loading as loadingOther } from "./user_actions";

export const SUCCESS_SUSCRIPTION = "SUCCESS_SUSCRIPTION";
export const LOADING_SUSCRIPTION = "LOADING_SUSCRIPTION";
export const FAILURE_SUSCRIPTION = "FAILURE_SUSCRIPTION";
export const INIT_SUSCRIPTION = "INIT_SUSCRIPTION";
export const INIT_TRANSFER = "INIT_TRANSFER";
export const INIT_PAYPAL = "INIT_PAYPAL";
export const INIT_STADISTIC = "INIT_STADISTIC";
let msgError =
  "Se ha producido un error inesperado. inténtalo de nuevo más tarde.";

export const success = () => {
  return { type: SUCCESS_SUSCRIPTION };
};

export const getStadistic = data => {
  return { type: INIT_STADISTIC, data };
};
export const failure = msj => {
  return { type: FAILURE_SUSCRIPTION, msj };
};

export const loading = () => {
  return { type: LOADING_SUSCRIPTION };
};

export const getSuscriptionInit = data => {
  return { type: INIT_SUSCRIPTION, data };
};

export const getTransferInit = data => {
  return { type: INIT_TRANSFER, data };
};

export const getPaypalInit = data => {
  return { type: INIT_PAYPAL, data };
};

const api = "https://novemapp.com:8080";
// const api = "https://dev.novemapp.com:8080";

export const listSuscriptions = () => {
  let url = "/suscription/all";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestData(url, api, token);
    dispatch(loading());
    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch(success());
          dispatch(getSuscriptionInit(result.data));
        }
      } catch (e) {}
    });
  };
};

export const transferInit = (suscription_id, type) => {
  let url = "/suscription/" + type + "-init";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(
      suscription_id,
      url,
      token
    );
    dispatch(loading());
    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch(success());
          
          if (type === "transfer") dispatch(getTransferInit(result.data));
          else dispatch(getPaypalInit(result.data));
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const transferPay = (suscription_id, type) => {
  let url = "/suscription/" + type + "-pay";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToUserProfession(
      suscription_id,
      url,
      token
    );
    dispatch(loadingOther(true));
    request.then(result => {
      try {

        dispatch(loadingOther(false));
        if (result.status === 200) {
          dispatch(
            showModal({
              open: true,
              message:
                "Su pago ha sido registrado, una vez validado por Novem estará activa su nueva suscripción"
            })
          );
        } else {
          dispatch(showModal({ open: true, message: result.msg }));
        }
      } catch (e) {
        dispatch(showModal({ open: true, message: msgError }));
      }
    });
  };
};

export const stadisticUser = () => {
  let url = "/suscription/stadistic-use";

  return (dispatch, getState) => {
    let token = getState().reducer.user.token.access_token;

    const request = apiNovemApp.requestToken(url, token);
    dispatch(loading());
    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch(getStadistic(result.data));
        } else {
        }
      } catch (e) {}
    });
  };
};
