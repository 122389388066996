import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class SessionId extends Component {
  render() {
    return (
      <div className="page-header">
        <h4 className="page-title">Documentos</h4>
        <ul className="breadcrumbs">
          <li className="nav-home">
            <Link to="/">
              <i className="flaticon-home"></i>
            </Link>
          </li>
          <li className="separator">
            <i className="flaticon-right-arrow"></i>
          </li>
          <li className="nav-item">
            <Link to="/">Documentos Recibidos</Link>
          </li>
        </ul>
      </div>
    );
  }
}
