import React /*, { useEffect, useState }*/ from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import { Field, reduxForm } from "redux-form";
import { transferPay } from "../../actions/suscription_actions";
import { PayPalButton } from "react-paypal-button-v2";
import { compose } from "redux";
import moment from "moment";
import "moment/locale/es";
import scriptLoader from "react-async-script-loader";

moment.locale("es");
const CLIENT_ID = {
  sandbox:
    "AZEPnG_JD4e2ZC8i1_XLbGlj76oPIb65EGAZCBI3hhnHPTtClNh0LzDb4klDQdwGy6XazEMslLsAlSZ3",
  production:
    "AZEPnG_JD4e2ZC8i1_XLbGlj76oPIb65EGAZCBI3hhnHPTtClNh0LzDb4klDQdwGy6XazEMslLsAlSZ3"
};

const typeCart = ({
  transferPay,
  back,
  close,
  amount,
  currency,
  onSuccess,
  initPaypal,
  suscription_id
}) => {
  return (
    <div>
      <PayPalButton
        amount={amount}
        currency={currency}
        style={{
          layout: "horizontal",
          label: "paypal",
          tagline: false,
          color: "silver"
        }}
        catchError={e => {
          console.log("ocurrio un error", e);
        }}
        onSuccess={(details, data) => {

          onSuccess(details, data);
          let values = {
            suscription_id: suscription_id,
            bank_id: initPaypal.banks[0]._id,
            paymnent_detail: {
              bank: initPaypal.banks[0].type_bank_account,
              headline: details.payer.email_address,
              dni: details.payer.payer_id,
              reference: details.id,
              amount: initPaypal.usd_cost,
              date: moment().format("YYYY-MM-DD")
            }
          };

          close();
          back();
          transferPay(values, "paypal");
        }}
        options={{
          clientId:
            "AZEPnG_JD4e2ZC8i1_XLbGlj76oPIb65EGAZCBI3hhnHPTtClNh0LzDb4klDQdwGy6XazEMslLsAlSZ3",
          commit: true,
          currency: "USD"
        }}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    initPaypal: state.suscription.initPaypal
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      transferPay
    },
    dispatch
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}`)
)(typeCart);
