import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  getEMD,
  selectAppointment,
  listPatients,
  addPatientBackup
} from "../../actions/appointment_actions";
import Search from "../Search";
import PrivateSecurity from "../common/PrivateSecurity";
import PatientForm from "../sidebar/maintainer/patientForm";
import Loading from "../Loading";

class Patient extends Component {
  state = {
    count: 0,
    visiblePatients: [],
    patients: [],
    type: true,
    option: false
  };

  increment() {
    this.setState({ count: this.state.count + 8 });
  }

  decrement() {
    this.setState({ count: this.state.count - 8 });
  }

  static getDerivedStateFromProps(props, state) {
    if (state.patients.length > 0 && state.patients !== state.visiblePatients) {
      return { visiblePatients: state.patients };
    }

    if (props.patients !== state.visiblePatients) {
      let newArray = [];
      props.patients.map(item => (item.user ? newArray.push(item) : null));
      return { visiblePatients: newArray };
    }

    return null;
  }

  render() {
    const {
      //patients,
      handleSubmit,
      loading,
      history,
      listInsurance,
      user
    } = this.props;
    const { option } = this.state;
    const onFormSubmit = data => {
      let values = {
        user_id: data.user._id
      };

      this.props.dispatch(selectAppointment(data));
      this.props.dispatch(getEMD(values, history));
    };
    var finit = Math.round(
      this.state.visiblePatients && this.state.visiblePatients.length / 8
    );

    const onSubmit = data => {
      var insurance =
        data.insurance && listInsurance.insurances_list[0][data.insurance - 1];
      let values = {
        date: data.date,
        emd_type: user.medical.emd_type,
        user: {
          dni: `${data.nationality}-${data.dni}`,
          name: data.name,
          lastname: data.lastname,
          birthdate: data.birthdate,
          phones: !data.phone
            ? []
            : [
                {
                  country_code: data.phone ? "+58" : "",
                  number: data.phone ? data.phone : ""
                }
              ],
          emails: !data.email
            ? []
            : [
                {
                  email: data.email
                }
              ],
          gender: data.gender,
          insurances: !insurance
            ? []
            : [
                {
                  carrier: insurance && insurance.name
                }
              ]
        }
      };
      this.props.dispatch(addPatientBackup(values));
    };

    const createTable = value => {
      let table = [];
      if (value.length>8)
      for (let i = 0; i <= value; i++) {
        table.push(
          <li className="page-item" key={i}>
            <button
              style={{
                color: i * 3 === this.state.count ? "#ffffff" : "#19769F",
                backgroundColor: i * 3 === this.state.count ? "#19769F" : null
              }}
              className="page-link"
              onClick={() => {
                this.setState({ count: i * 8 });
              }}
            >
              {i + 1}
            </button>
          </li>
        );
      }
      return table;
    };

    return (
      <PrivateSecurity title={"Pacientes"}>
        {!option ? (
          <div className="card full-height">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <div className="card-title">
                  <h4
                    style={{
                      fontFamily: "Poppins",
                      color: "#19769f",
                      fontWeight: "bold"
                    }}
                  >
                    Lista de pacientes
                  </h4>
                </div>
                <div className="row ml-auto">
                  <div className="col-auto">
                    <h5
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold"
                      }}
                    >
                      Pacientes o historias:
                    </h5>
                  </div>
                  <div className="col">
                    <button
                      className="btn"
                      style={{
                        backgroundColor: "#19769F",
                        color: "#ffffff"
                      }}
                      onClick={() => {
                        this.setState({ option: true });
                      }}
                    >
                      Agregar
                    </button>
                  </div>
                  <div className="col-auto">
                    <Search
                      data={this.state.visiblePatients}
                      filterData={(query, data) => {
                        if (data.toLowerCase().includes(query)) {
                          return true;
                        } else return false;
                      }}
                      onUpdateData={data => {
                        this.setState({ patients: data });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {loading && <Loading />}
            {!loading && (
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th>Nº de expediente</th>
                        <th>Paciente</th>
                        <th>Cédula</th>
                        <th>Revisar</th>
                      </tr>
                      {this.state.visiblePatients && 
                        this.state.visiblePatients
                          .slice(this.state.count, this.state.count + 8)
                          /*.sort(
                          (a, b) =>
                            a.user &&
                            a.user.name.localeCompare(b.user && b.user.name)
                        )*/
                          .map(
                            (item, i) =>
                              item.user && (
                                <tr key={i}> 
                                  <td>{item.user.identificator}</td>
                                  <td className="font-weight-600">
                                    {item.user.name} {item.user.lastname}
                                  </td>
                                  <td>{item.user.dni}</td>
                                  <td>
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={handleSubmit(() =>
                                        onFormSubmit(item)
                                      )}
                                    >
                                      Ver EMD
                                    </button>
                                  </td>
                                </tr>
                              )
                          )}
                    </tbody>
                  </table>
                  <br />
                  <div className="align-content-center justify-content-center">
                    <ul className="pagination justify-content-center">
                      <li className="page-item">
                        {this.state.count > 0 ? (
                          <button
                            style={{ color: "#19769F" }}
                            onClick={this.decrement.bind(this)}
                            className="page-link"
                          >
                            <i className="fas fa-angle-double-left"></i>
                          </button>
                        ) : (
                          false
                        )}
                      </li>
                      {createTable(finit)}
                      {this.state.count + 8 < finit * 8 ? (
                        <li className="page-item">
                          <button
                            style={{ color: "#19769F" }}
                            onClick={this.increment.bind(this)}
                            className="page-link"
                          >
                            <i className="fas fa-angle-double-right"></i>
                          </button>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="justify-content-center">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <PatientForm date={true} />
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                display: "flex"
              }}
            >
              <button
                type="button"
                style={{ backgroundColor: "#19769F", fontColor: "#fff" }}
                onClick={handleSubmit(onSubmit)}
                className="btn"
              >
                Guardar
              </button>
            </div>
          </div>
        )}
      </PrivateSecurity>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    patients: state.reducer.patients,
    listInsurance: state.reducer.listInsurance,
    loading: state.reducer.loadingPatient
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getEMD, selectAppointment, addPatientBackup, listPatients },
    dispatch
  );

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "AddAppoinment"
  })
)(Patient);
