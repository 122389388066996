import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import {
  listSuscriptions,
  transferInit
} from "../../actions/suscription_actions";
import Loading from "../Loading";
import FormPay from "./FormPay";
import PayPalBtn from "./typeCart";
//import PayPalBtn from "./Button";

/*const Price = ({ title, amount }) => {
  return (
    <div className="col-sm-6 col-md-6 col-lg-6">
      <div className="card card-stats card-round">
        <div className="card-body">
          <div className="row">
            <div className="col-5">
              <div className="icon-big text-center">
                <i className="flaticon-coins text-success"></i>
              </div>
            </div>
            <div className="col-7 col-stats">
              <div className="numbers">
                <p className="card-category">{title}</p>
                <h4 className="card-title">{amount.toLocaleString()}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};*/

const Item = ({ title, data }) => {
  return (
    <div className="row">
      <div className="col-4">
        <b>{title}</b>
      </div>
      <div className="col-8">{data}</div>
    </div>
  );
};

const PaySuscription = ({
  user,
  listSuscriptions,
  transferInit,
  success,
  suscription,
  initTransfer,
  error,
  loading,
  back
}) => {
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState(false);

  const [typeCart, settypeCart] = useState("paypal");
  

  return (
    <>
      {loading && !initTransfer && <Loading />}

      {initTransfer && (
        <>
          {/* <div className="col-md-5 col-lg-3 col-sm-3 pl-md-0">
            <div className="card-pricing2 card-success">
              <div className="pricing-header">
                <h3 className="fw-bold">{suscription.name}</h3>
                <span className="sub-title">{suscription.description}</span>
              </div>
              <div className="price-value">
                <div className="value">
                  <span className="currency">{suscription.cost.unit}</span>
                  <span className="amount">{suscription.cost.amount}</span>
                  <span className="month">/year</span>
                </div>
              </div>
              <div
                style={{
                  paddingTop: "50px"
                }}
              >
                {suscription.features.map((feature, i) => (
                  <div key={i}>
                    <ul
                      className="pricing-content"
                      style={{
                        padding: "0px",
                        margin: "0px"
                      }}
                    >
                      <p style={{ color: "black", textAlign: "center" }}>
                        {feature.type_feature === "office"
                          ? "Oficina"
                          : feature.type_feature === "certification"
                          ? "Certificación"
                          : feature.type_feature === "appointment" 
                          ? "Citas"
                          : feature.type_feature === "support"
                          ? "Soporte"
                          : `${feature.type_feature
                              .charAt(0)
                              .toUpperCase()}${feature.type_feature.slice(
                              1,
                              feature.type_feature.length
                            )}`}
                      </p>
                    </ul>
                    <ul
                      className="pricing-content"
                      style={{ paddingTop: "0px" }}
                    >
                      <li  >
                        {feature.description}
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            </div>
                              </div>*/}

          <div className="container">
            <div className="row">
              <div className="col-sm">
                <div className="card">
                  <div className="card-header">
                    <h4
                      style={{
                        fontFamily: "Poppins",
                        color: "#19769f",
                        fontWeight: "bold"
                      }}
                    >
                      Detalles de la suscripción
                    </h4>
                  </div>
                  <div className="card-body">
                    <p className="row m-0">
                      <p className="font-weight-bold">
                        Nombre del plan:&nbsp;&nbsp; {"  "}
                      </p>
                      {suscription.name}
                    </p>

                    {suscription.features.map((feature, i) => (
                      <p key={i} className="row m-0">
                        <p className="font-weight-bold">
                          {feature.type_feature === "office"
                            ? "Oficina"
                            : feature.type_feature === "certification"
                            ? "Certificación"
                            : feature.type_feature === "appointment"
                            ? "Citas"
                            : feature.type_feature === "support"
                            ? "Soporte"
                            : `${feature.type_feature
                                .charAt(0)
                                .toUpperCase()}${feature.type_feature.slice(
                                1,
                                feature.type_feature.length
                              )}`}
                          :&nbsp;&nbsp;{"  "}
                        </p>

                        {feature.description}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="card">
                  {/* <div className="card-header">
                    <h4
                      style={{
                        fontFamily: "Poppins",
                        color: "#19769f",
                        fontWeight: "bold"
                      }}
                    >
                      Detalles del pago
                    </h4>
                  </div> */}
                  <div className="card-body">
                    <div className="form-group form-show-validation row">
                      <label className="col-lg-3 col-md-3 col-sm-4 mt-sm-2 text-right">
                        Tipo de pago <span className="required-label">*</span>
                      </label>
                      <div className="col-lg-4 col-md-9 col-sm-8 d-flex align-items-center">
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id="cartbs"
                            value="$"
                            name="gender"
                            checked={typeCart === "$"}
                            onChange={e => settypeCart(e.target.value)}
                            className="custom-control-input"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="cartbs"
                          >
                            Pago por Zelle
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            id="cartpaypal"
                            type="radio"
                            value="paypal"
                            name="gender"
                            checked={typeCart === "paypal"}
                            onChange={e => settypeCart(e.target.value)}
                            className="custom-control-input"
                          />{" "}
                          <label
                            className="custom-control-label"
                            htmlFor="cartpaypal"
                          >
                            Paypal
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      className="form-group form-show-validation "
                      style={{ background: "#f1f1f1" }}
                    >
                      {typeCart === "$" ? (
                        <>
                          <label className="col-12 m-0 mt-2">
                            Datos a transferir
                          </label>
                          <div className="col-lg-12" >
                            <div className="card-sub m-0 pt-0" style={{ background: "#f1f1f1" }}>
                              <Item title={"Nombre:"} data={"María Rodríguez"} />
                              <Item title={"Correo:"} data={"pagos@novemapp.com"} />
                            </div>
                            <div>
                              <b>NOTA: </b>
                              <p>El pago debe realizarse en 24horas.</p>
                              <p>Recuerde llenar todos sus datos médicos para poderlo validar en el sistema.</p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                      Para completar la transacción, usted sera redirigido a
                      los servicios seguros de PayPal.
                    </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="card">
                  <div className="card-header">
                    <h4
                      style={{
                        fontFamily: "Poppins",
                        color: "#19769f",
                        fontWeight: "bold"
                      }}
                    >
                      Resumen
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      {typeCart === "bs" ? (
                        <>
    
                        </>
                      ) : (
                        <>
                          <table
                            className="table table-bordered p-3"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  className="w-75"
                                  style={{
                                    border: "0px",
                                    height: "auto",
                                    padding: "5px!important"
                                  }}
                                >
                                  Precio original:
                                </td>
                                <td
                                  className="w-25"
                                  style={{
                                    border: "0px",
                                    height: "auto",
                                    padding: "5px 25px!important"
                                  }}
                                >
                                  {initTransfer.usd_cost} US$
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    border: "0px",
                                    height: "auto",
                                    padding: "5px 25px!important"
                                  }}
                                >
                                  Descuento:
                                </td>
                                <td
                                  style={{
                                    border: "0px",
                                    height: "auto",
                                    padding: "5px 25px!important"
                                  }}
                                >
                                  -0,0 US$
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table className="table table-bordered">
                            <tbody className="my-3">
                              <tr>
                                <td
                                  className="w-75 font-weight-bold"
                                  style={{
                                    border: "0px",
                                    height: "auto",
                                    padding: "5px 25px!important"
                                  }}
                                >
                                  Total en US$:
                                </td>
                                <td
                                  className="w-25 font-weight-bold"
                                  style={{
                                    border: "0px",
                                    height: "auto",
                                    padding: "5px 25px!important"
                                  }}
                                >
                                  {initTransfer.usd_cost} US$
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      )}
                    </div>

                    {typeCart === "paypal" ? (
                      <PayPalBtn
                        close={() => setModal(false)}
                        suscription_id={suscription._id}
                        back={back}
                        amount={initTransfer.usd_cost}
                        currency={"USD"}
                      />
                    ) : (
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn"
                          style={{
                            backgroundColor: "#19769F",
                            color: "#ffffff"
                          }}
                          onClick={() => {
                            setModal(true);
                            setItem(suscription._id);
                          }}
                        >
                          Registrar pago
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Pagar</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <FormPay
            close={() => setModal(false)}
            suscription_id={item}
            back={back}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    success: state.suscription.success,
    loading: state.suscription.loading,
    error: state.suscription.error,
    initTransfer: state.suscription.initTransfer
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      listSuscriptions,
      transferInit
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PaySuscription);
