import React, { Component } from "react";
import {
  Field,
  reduxForm,
  initialize as initializeForm,
  formValueSelector
} from "redux-form";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { findByDni } from "../../../actions/appointment_actions";
import { validate } from "../../form/validate";
import "moment/locale/es";
import { css } from "@emotion/core";
import { GridLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
`;

moment.locale("es");
/*import Header from "../../../components/Header";
import Sidebar from "../../../components/sidebar/Sidebar";
import UserGreet from "../../../consts/UserGreet";
import SessionId from "./SessionId";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";*/

const data = [
  { id: 1, name: "name", title: "Nombre", type: "text" },
  { id: 2, name: "lastname", title: "Apellido", type: "text" },
  { id: 3, name: "birthdate", title: "Fecha de nacimiento", type: "date" },
  { id: 4, name: "phone", title: "Telef.", type: "number" },
  { id: 5, name: "email", title: "Email", type: "email" }
];

var disabled = true,
  valuesInput;

const renderField = ({
  input,
  name,
  className,
  max,
  type,
  disabled,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <input
          className={className}
          {...input}
          type={type}
          max={max}
          disabled={disabled}
        />
        {touched && error && (
          <span
            style={{ fontFamily: "Poppins", color: "red", textAlign: "center" }}
          >
            {error}
          </span>
        )}
      </div>
    </div>
  );
};

const asyncValidate = (values, dispatch, props) => {
  let init = {
    name: "",
    lastname: "",
    email: "",
    phone: "",
    gender: "",
    insurance: "Ninguno",
    nationality: values.nationality,
    dni: values.dni
  };

  return new Promise(resolve => {
    values.dni &&
      dispatch(findByDni({ dni: `${values.nationality}-${values.dni}` })).then(
        result => {
          disabled = false;
          if (result.data === null) {
            valuesInput = init;
            dispatch(props.initializeForm("AddAppoinment", init));
          }
          if (result.data) {
            let number = result.data.dni,
              gender = result.data.gender,
              nationality = result.data.dni.charAt(0),
              insurance =
                result.data.insurances &&
                result.data.insurances.length > 0 &&
                result.data.insurances[0].carrier,
              resultInsurance = "Ninguno",
              email = result.data.email;

            if (isNaN(number))
              number = result.data.dni.slice(2, result.data.dni.length);

            if (gender === "Femenino" || gender === "femela") {
              gender = "Femenino";
            } else gender = "Masculino";

            if (typeof email === "undefined")
              email = result.data.emails ? result.data.emails[0].email : null;

            if (!isNaN(nationality)) {
              nationality = values.nationality;
            }

            if (insurance.length > 0 && insurance) {
              resultInsurance =
                props.listInsurance &&
                props.listInsurance.insurances_list &&
                props.listInsurance.insurances_list[0].find(
                  result => result.name === insurance
                );
            }

            let init = {
              nationality: nationality,
              dni: number,
              name: result.data.name,
              lastname: result.data.lastname,
              email: email,
              birthdate: result.data.birthdate && result.data.birthdate,
              gender: gender,
              phone:
                result.data.phones && result.data.phones.length > 0
                  ? result.data.phones[0].number.charAt(0) === "0"
                    ? result.data.phones[0].number.slice(
                        1,
                        result.data.phones[0].number.length
                      )
                    : result.data.phones[0].number
                  : null,
              insurance:
                resultInsurance !== "Ninguno"
                  ? resultInsurance && resultInsurance._id
                  : "Ninguno"
            };
            valuesInput = init;
            props.initializeForm("AddAppoinment", init);
          }
        }
      );
  });
};

class AddAppoinment extends Component {
  state = {
    since: "",
    until: "",
    phone: "",
    disabled: true
  };

  componentDidMount() {
    disabled = true;

    let init = {
      clinic:
        this.props.user.medical &&
        this.props.user.medical.offices &&
        this.props.user.medical.offices.length > 0 &&
        this.props.user.medical.offices[0]._id,
      insurance: "Ninguno"
    };

    valuesInput = init;

    initializeForm("AddAppoinment", init);
  }

  render() {
    const { listInsurance, loadingFind, errorFind, msgFind, date } = this.props;

    return (
      <div className="card">
        <div className="card-header">
          <h4
            style={{
              fontFamily: "Poppins",
              color: "#19769f",
              fontWeight: "bold"
            }}
          >
            Datos del paciente
          </h4>
        </div>
        <div className="card-body">
          <div className="row mt-3" style={{ justifyContent: "center" }}>
            <div className="col-md-2">
              <div className="form-group">
                <label>Cédula </label>
                <Field
                  style={{ width: "5rem" }}
                  name="nationality"
                  component="select"
                  className="form-control nationality"
                  onClick={() => {
                    this.setState({ disabled: false });
                  }}
                >
                  <option></option>
                  <option value={"V"}>V</option>
                  <option value={"E"}>E</option>
                </Field>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label></label>
                <Field
                  name="dni"
                  className="form-control"
                  component={renderField}
                  type="number"
                  disabled={this.state.disabled}
                />
              </div>
            </div>
          </div>
          {errorFind ? (
            <div>
              <h6
                style={{
                  fontFamily: "Poppins",
                  color: "red",
                  textAlign: "center"
                }}
              >
                {msgFind}, puede crearlo.
              </h6>
            </div>
          ) : null}
          {loadingFind ? (
            <div
              style={{
                padding: 25
              }}
            >
              <GridLoader css={override} color={"#19769F"} loading={true} />
            </div>
          ) : (
            !disabled && (
              <div className="row">
                {data.map((item, i) => (
                  <div className="col-md-6" key={item.id}>
                    <div className="form-group">
                      <label>{item.title} </label>
                      <Field
                        name={item.name}
                        className="form-control"
                        component={renderField}
                        type={item.type}
                        max={
                          item.type === "date"
                            ? moment().format("YYYY-MM-DD")
                            : null
                        }
                        disabled={
                          item.name === "name" && valuesInput.name
                            ? true
                            : item.name === "lastname" && valuesInput.lastname
                            ? true
                            : item.name === "email" && valuesInput.email
                            ? true
                            : item.name === "phone" && valuesInput.phone
                            ? true
                            : item.name === "birthdate" && valuesInput.birthdate
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                ))}

                {/*<div className="col-md-6">
                <div className="form-group">
                  <label>Telef.</label>
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true
                    }}
                    country={"ve"}
                    value={this.state.phone}
                    onChange={phone => this.setState({ phone })}
                  />
                </div>
              </div>*/}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Sexo </label>
                    <Field
                      name="gender"
                      component="select"
                      className="form-control"
                      disabled={valuesInput.gender ? true : false}
                    >
                      <option value={"Femenino"}>Femenino</option>{" "}
                      <option value={"Masculino"}>Masculino</option>
                    </Field>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Seguro </label>
                    <Field
                      name="insurance"
                      component="select"
                      className="form-control"
                      disabled={
                        valuesInput.insurance &&
                        valuesInput.insurance !== "Ninguno"
                          ? true
                          : false
                      }
                    >
                      {listInsurance &&
                        listInsurance.insurances_list &&
                        listInsurance.insurances_list[0].map((item, i) => (
                          <option key={i} value={parseInt(item._id)}>
                            {item.name}
                          </option>
                        ))}
                      <option value={"Ninguno"}>Ninguno</option>
                    </Field>
                  </div>
                </div>
                {date && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Fecha de consulta</label>
                      <Field
                        name="date"
                        component={renderField}
                        disabled={disabled}
                        type="date"
                        className="form-control"
                        max={moment().format("YYYY-MM-DD")}
                      />
                    </div>
                  </div>
                  )}
                  {/* <div className="col-md-6">
                    <div className="form-group">
                    <label>Tipo de EMD</label>
                    <Field
                      name="emd_type"
                      component="select"
                      className="form-control"
                    >
                      <option></option>
                      <option value={"basic"}>Medicina General</option>
                      <option value={"odontology"}>Odontológico</option>
                    </Field>
                    </div>
                  </div> */}
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

const selector = formValueSelector("AddAppoinment");

const mapStateToProps = (state, ownProps) => {
  const {
    name,
    lastname,
    gender,
    birthdate,
    phone,
    email,
    insurance
  } = selector(
    state,
    "name",
    "lastname",
    "gender",
    "birthdate",
    "phone",
    "email",
    "insurance"
  );

  return {
    name,
    lastname,
    gender,
    birthdate,
    phone,
    email,
    insurance,
    user: state.reducer.user,
    listInsurance: state.reducer.listInsurance,
    loadingFind: state.reducer.loadingFind,
    successFind: state.reducer.successFind,
    errorFind: state.reducer.errorFind,
    msgFind: state.reducer.msgFind
  };
};

const mapDispatchToProps = dispatch => {
  bindActionCreators({ findByDni }, dispatch);
  return {
    initializeForm: (formName, values) =>
      dispatch(initializeForm(formName, values))
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: "AddAppoinment",
    validate,
    asyncValidate,
    asyncBlurFields: ["dni"]
  })
)(AddAppoinment);
