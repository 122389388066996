import React, { Component } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { saveTooth } from "../../actions/emd_actions";
import CrudButtons from "../../consts/CrudButtons";
import Loading from "../Loading";
import AutoCompleteComponentTeeth from "./AutoCompleteComponentTeeth";

import moment from "moment";
import "moment/locale/es";

moment.locale("es");

const validate = values => {
  const errors = {};
  if (
    values.year2 &&
    moment(values.year2).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")
  ) {
    errors.year2 = "Fecha no válida";
  }

  return errors;
};

const renderField = ({
  input,
  name,
  className,
  type,
  disabled,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <input className={className} {...input} type={type} />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

class DentalClinicalExamination extends Component {
  state = {
    count: 0,
    form: false
  };

  increment() {
    this.setState({ count: this.state.count + 3 });
  }

  decrement() {
    this.setState({ count: this.state.count - 3 });
  }

  render() {
    const {
      data,
      handleSubmit,
      user,
      dispatch,
      loading,
      reset,
    } = this.props;

    const onFormSubmit = data => {
      let values = {
        user: user._id,
        tooth: {
          name: this.props.toothTermValue,
          procedure: {
            name: data.procedure
          }
        }
      };
      console.log("esta es values ", values)
      
      dispatch(saveTooth(values));
      this.setState({ form: false });
      reset("Teeth");
    };
    
    let finit;
    if (data) finit = Math.round(data.length / 3);
    

    const createTable = value => {
      let table = [];

      for (let i = 0; i < value; i++) {
        table.push(
          <li className="page-item" key={i}>
            <button
              style={{
                color: i * 3 === this.state.count ? "#ffffff" : "#19769F",
                backgroundColor: i * 3 === this.state.count ? "#19769F" : null
              }}
              className="page-link"
              onClick={() => this.setState({ count: i * 3 })}
            >
              {i + 1}
            </button>
          </li>
        );
      }
      return table;
    };

    return (
      <div className="col-md-6">
        <div className="card full-height">
          <div className="card-header">
            <div className="d-flex align-items-center">
              <div className="card-title">
                Examen Clínico Dental
              </div>
              <div className="ml-auto">
                <button
                  onClick={() => {
                    this.setState({ form: !this.state.form });
                    reset("Teeth");
                  }}
                  className="btn btn-icon btn-round btn-primary text-center"
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            {this.state.form && (
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <label>Diente</label>
                
                    <AutoCompleteComponentTeeth />
                </div>
                </div>
                
                  <div className="col-md-5">
                    <div className="form-group">
                      <label>Procedimiento</label>
                      <Field
                        name="procedure"
                        component="select"
                        className="form-control"
                      >
                        <option value=""></option>
                        <option value="extraccion">Extracción</option>
                        <option value="corona">Corona</option>
                        <option value="conducto">Tratamiento de Conducto</option>
                        <option value="caries">Caries</option>
                        <option value="recidivas">Recidivas</option>
                        <option value="por extraer">Por Extraer</option>
                        <option value="fractura">Fractura</option>
                        <option value="ubturada">Ubturada</option>
                        <option value="por erupcionar">Por Erupcionar</option>
                        <option value="Corona bien adaptada">Corona bien adaptada</option>
                        <option value="protesis">Prótesis</option>
                      </Field>
                    </div>
                  </div>

                <div className="col-auto">
                  <div className="form-group">
                    <label>{/*Acción*/}</label>

                    <button
                      onClick={handleSubmit(onFormSubmit)}
                      className="btn btn-icon btn-round btn-primary text-center"
                    >
                      <i className="fas fa-check"></i>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {loading && <Loading />}

            {!loading && (
              <>
                <div className="separator-dashed"></div>
                {Array.isArray(data) &&
                  data.sort(function (a, b) {
                    a = new Date(a.created_at);
                    b = new Date(b.created_at);
                    if (a > b) return -1;
                    if (a < b) return 1;
                    return 0;
                  })
                    .slice(this.state.count, this.state.count + 3)
                    .map((item, i) => {
                      return (
                        <div key={i}>
                          <div className="d-flex">
                            <div className="flex-1 ml-3 pt-1">
                              <h6
                                className="text-uppercase fw-bold mb-1"
                                style={{ fontSize: ".65rem" }}
                              >
                                {item.name}
                              </h6>
                              <span className="text-muted">{item.name.es}</span>
                              <span className="text-warning pl-3"></span>
                            </div>
                            <div className="flex-1 ml-3 pt-1">
                              <h6
                                className="text-uppercase fw-bold mb-1"
                                style={{ fontSize: ".65rem" }}
                              >
                                {item.procedure.name}
                              </h6>
                            </div>
                            <CrudButtons
                              data={item}
                              user_id={user._id}
                              clickHandler={"tooth"}
                            />
                          </div>

                          <div className="separator-dashed"></div>
                        </div>
                      );
                    })}
                <br />
                <div className="align-content-center justify-content-center">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      {this.state.count > 0 ? (
                        <button
                          style={{ color: "#19769F" }}
                          onClick={this.decrement.bind(this)}
                          className="page-link"
                        >
                          <i className="fas fa-angle-double-left"></i>
                        </button>
                      ) : (
                          false
                        )}
                    </li>
                    {createTable(finit)}
                    {this.state.count + 3 < finit * 3 ? (
                      <li className="page-item">
                        <button
                          style={{ color: "#19769F" }}
                          onClick={this.increment.bind(this)}
                          className="page-link"
                        >
                          <i className="fas fa-angle-double-right"></i>
                        </button>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </>
            )}
          </div>
         
        </div>
      </div>
    );
  }
}


// const selector = formValueSelector("Teeth");

const mapDispatchToProps = dispatch => {
  bindActionCreators({ saveTooth }, dispatch);
};

export default compose(
  connect(state => {
    // const tooth = selector(state);
    // or together as a group
    return {
      loading: state.reducer.loadingTooth,
      toothTermValue: state.reducer.toothTermValue,
      mapDispatchToProps,
      // selector
    };
  }),
  reduxForm({
    form: "Teeth",
    initialValues: {},
    validate
  })
)(DentalClinicalExamination);





