import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
import logger from "redux-logger";

const middleware = [thunk];

//  .env produccion,
// if (process.env.NODE_ENV === `development`) {
//   middleware.push(logger);
// }

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
