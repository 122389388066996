import React from "react";
import { Field, reduxForm } from "redux-form";
import LoginRegister from "../../consts/LoginRegister";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { register } from "../../actions/user_actions";
import moment from "moment";
import "moment/locale/es";
import "./style.css";
moment.locale("es");

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = "Obligatorio";
  } else if (values.name.length < 3) {
    errors.name = "Debe tener mínimo 3 caracteres";
  }

  if (!values.lastname) {
    errors.lastname = "Obligatorio";
  } else if (values.lastname.length < 3) {
    errors.lastname = "Debe tener mínimo 3 caracteres";
  }

  if (!values.birthdate) {
    errors.birthdate = "Obligatorio";
  }

  if (!values.gender) {
    errors.gender = "Obligatorio";
  }

  if (!values.email) {
    errors.email = "Obligatorio";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values && values.email)
  ) {
    errors.email = "Dirección de correo electrónico no válida";
  }

  if (!values.password) {
    errors.password = "Obligatorio";
  } else if (values.password.length < 6) {
    errors.password = "Debe tener 6 caracteres o mas";
  }

  if (!values.rePassword) {
    errors.rePassword = "Obligatorio";
  } else if (values.rePassword.length < 6) {
    errors.rePassword = "Debe tener 6 caracteres o mas";
  } else if (values.rePassword !== values.password) {
    errors.rePassword = "No coinciden la contraseña";
  }

  return errors;
};

export const renderField = ({
  input,
  name,
  className,
  type,
  placeholder,
  max,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <input
          className={className}
          {...input}
          type={type}
          placeholder={placeholder}
          max={max}
        />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      hidden2: true
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.toggleShow2 = this.toggleShow2.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  toggleShow2() {
    this.setState({ hidden2: !this.state.hidden2 });
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <LoginRegister>
        <div className="login-aside w-50 d-flex align-items-center justify-content-center bg-white">
          <div className="container container-login container-transparent animated fadeIn">
            <div className="login-form">
              <h3 className="text-center">Registro a NovemApp</h3>
              <form
                onSubmit={handleSubmit(values =>
                  this.props.dispatch(register(values))
                )}
              >
                <div className="form-group">
                  <label className="placeholder">
                    <b>Nombre</b>
                  </label>
                  <Field
                    name="name"
                    placeholder="Nombre"
                    className="form-control"
                    component={renderField}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label className="placeholder">
                    <b>Apellido</b>
                  </label>
                  <Field
                    name="lastname"
                    placeholder="Apellido"
                    className="form-control"
                    component={renderField}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label className="placeholder">
                    <b>Fecha de Nacimiento</b>
                  </label>
                  <div className="position-relative">
                    <Field
                      name="birthdate"
                      className="form-control"
                      component={renderField}
                      type="date"
                      max={moment().format("YYYY-MM-DD")}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="placeholder">
                    <b>Email</b>
                  </label>
                  <div className="position-relative">
                    <Field
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      component={renderField}
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="placeholder">
                    <b>Contraseña</b>
                  </label>
                  <div className="position-relative">
                    <Field
                      className="form-control"
                      placeholder="Contraseña"
                      name="password"
                      component={renderField}
                      type={this.state.hidden ? "password" : "text"}
                    />
                    <div className="show-password">
                      <button
                        style={{
                          position: "absolute",
                          top: -25,
                          left: -30,
                          right: 5,
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                          color: "#19769f"
                        }}
                        onClick={this.toggleShow}
                        type="button"
                      >
                        <i
                          className={
                            this.state.hidden
                              ? "far fa-eye"
                              : "far fa-eye-slash"
                          }
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="placeholder">
                    <b>Repetir contraseña</b>
                  </label>
                  <div className="position-relative">
                    <Field
                      className="form-control"
                      placeholder="Repetir contraseña"
                      name="rePassword"
                      component={renderField}
                      type={this.state.hidden2 ? "password" : "text"}
                    />
                    <div className="show-password">
                      <button
                        style={{
                          position: "absolute",
                          top: -25,
                          left: -30,
                          right: 5,
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                          color: "#19769f"
                        }}
                        onClick={this.toggleShow2}
                        type="button"
                      >
                        <i
                          className={
                            this.state.hidden2
                              ? "far fa-eye"
                              : "far fa-eye-slash"
                          }
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-check">
                  <label>Género</label>
                  <div className="row">
                    <div className="col-md-4">
                      <label className="form-radio-label ml-4">
                        Femenino
                        <Field
                          className="form-radio-input"
                          name="gender"
                          component={renderField}
                          type="radio"
                          value="Femenino"
                        />
                      </label>
                    </div>
                    <div className="col-md-4">
                      <label className="form-radio-label ml-4">
                        Masculino
                        <Field
                          className="form-radio-input"
                          name="gender"
                          component={renderField}
                          type="radio"
                          value="Masculino"
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn-primary col-md-5 float-right mt-3 mt-sm-0 fw-bold"
                    type="submit"
                  >
                    Crear Cuenta
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </LoginRegister>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ register }, dispatch);

export default compose(
  reduxForm({
    form: "SignUp",
    validate
  }),
  connect(null, mapDispatchToProps)
)(SignUp);
