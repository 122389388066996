import axios from "axios";

const api = "https://novemapp.com:8081";
// const api = "http://0.0.0.0:8081";

// USER LOGIN AND REGISTER

export const requestUser = (data, url) =>
  axios.post(`${api}${url}`, data).then(res => {
    const result = res.data;
    return result;
  });

export const requestPassword = (data, url, server) =>
  axios.post(`${server}${url}`, data).then(res => {
    const result = res.data;
    return result;
  });

export const requestData = (url, apiUrl, token) =>
  axios
    .post(`${apiUrl}${url}`, null, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => {
      const result = res.data;
      return result;
    });

export const requestEdit = (data, url, apiUrl, token) =>
  axios
    .post(`${apiUrl}${url}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => {
      const result = res.data;
      return result;
    });

// SPECIALTY, OFFICE, INSURANCE AND SCHEDULE

export const requestToUserProfession = (data, url, token) =>
  axios
    .post(`${api}${url}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => {
      const result = res.data;
      return result;
    });

export const requestToken = (url, token) =>
  axios
    .post(`${api}${url}`, null, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => {
      const result = res.data;
      return result;
    })
    .catch(e => {
      console.log("Expired Token");
    });

// APPOINTMENT

export const requestToListAppointment = (data, url, token) =>
  axios
    .post(`${api}${url}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => {
      const result = res.data;
      return result;
    });

export const requestToUserAppointment = (data, url, token) =>
  axios
    .post(`${api}${url}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => {
      const result = res.data;
      return result;
    });
