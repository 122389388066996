import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { showModal } from "../../actions/user_actions";
import {
  appointmentCheckIn,
  initConsultation
} from "../../actions/appointment_actions";
import moment from "moment";
import "moment/locale/es";
import PrivateSecurity from "../common/PrivateSecurity";
moment.locale("es");

const orden = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];

class DoctorConsultation extends Component {
  state = {
    data: [],
    form1: true,
    form2: false,
    count: 0
  };

  handleSubmit(data, user) {
    // user.emd_type nos dara odontology o basic 
    let values = {
      appointment_id: data._id,
      patient_id: user._id,
      emd_type: this.props.user.medical.emd_type
    };
    this.props.appointmentCheckIn(values, this.props.history);
    this.props.initConsultation({type_fomulary: values.emd_type});
  }

  increment() {
    this.setState({ count: this.state.count + 5 });
  }

  decrement() {
    this.setState({ count: this.state.count - 5 });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.confirmed.data) {
      props.confirmed.data.map(item => {
        item["mes"] = moment(item.date).format("MMMM");
        item["data"] = item;
        return item;
      });

      var nuevoArray = [];
      var arrayTemporal = [];

      const filter = i => {
        arrayTemporal = nuevoArray.filter(
          resp => resp["Mes"] === props.confirmed.data[i]["mes"]
        );
      };
      for (
        var i = 0;
        i < (props.confirmed.data && props.confirmed.data.length);
        i++
      ) {
        filter(i);

        if (arrayTemporal.length > 0) {
          nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["Data"].push({
            medical_id: props.confirmed.data[i]["medical_id"],
            created_at: props.confirmed.data[i]["created_at"],
            date: props.confirmed.data[i]["date"],
            deleted_at: props.confirmed.data[i]["deleted_at"],
            hour_id: props.confirmed.data[i]["hour_id"],
            medical: props.confirmed.data[i]["medical"],
            office_id: props.confirmed.data[i]["office_id"],
            patients: props.confirmed.data[i]["patients"],
            schedule_id: props.confirmed.data[i]["schedule_id"],
            updated_at: props.confirmed.data[i]["updated_at"],
            _id: props.confirmed.data[i]["_id"]
          });
        } else {
          nuevoArray.push({
            Mes: props.confirmed.data[i]["mes"],
            Data: [
              {
                medical_id: props.confirmed.data[i]["medical_id"],
                created_at: props.confirmed.data[i]["created_at"],
                date: props.confirmed.data[i]["date"],
                deleted_at: props.confirmed.data[i]["deleted_at"],
                hour_id: props.confirmed.data[i]["hour_id"],
                medical: props.confirmed.data[i]["medical"],
                office_id: props.confirmed.data[i]["office_id"],
                patients: props.confirmed.data[i]["patients"],
                schedule_id: props.confirmed.data[i]["schedule_isd"],
                updated_at: props.confirmed.data[i]["updated_at"],
                _id: props.confirmed.data[i]["_id"]
              }
            ]
          });
        }
      }

      let objsOrdenados = [];

      orden.forEach(elem1 => {
        nuevoArray.forEach(elem2 => {
          if (elem2.Mes === elem1.toLowerCase()) {
            objsOrdenados.push(elem2);
          }
        });
      });

      if (props.confirmed.data !== state.data) {
        return { data: objsOrdenados };
      }
    }

    return null;
  }

  render() {
    var finit = Math.round(this.state.user && this.state.user.length / 5);

    const createTable = value => {
      let table = [];

      for (let i = 0; i < value; i++) {
        table.push(
          <li className="page-item" key={i}>
            <button
              className="page-link"
              onClick={() => {
                this.setState({ count: i * 5 });
              }}
            >
              {i + 1}
            </button>
          </li>
        );
      }
      return table;
    };

    return (
      <PrivateSecurity title={"Consultas"}>
        <div className="card full-height consultation-list">
          <div className="card-header">
            <div className="d-flex align-items-center">
              <div className="card-title">
                <h4
                  style={{
                    fontFamily: "Poppins",
                    color: "#19769f",
                    fontWeight: "bold"
                  }}
                >
                  Listado de consultas
                </h4>
              </div>
              <div className="ml-auto">
                {this.state.form2 && (
                  <h6
                    onClick={() =>
                      this.setState({
                        form1: true,
                        form2: false,
                        user: []
                      })
                    }
                    className="btn"
                    style={{
                      backgroundColor: "#19769F",
                      color: "#ffffff"
                    }}
                  >
                    Volver
                  </h6>
                )}
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <div
              className="row row-projects"
              style={{
                paddingTop: 10,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10
              }}
            >
              {this.state.form1 &&
                this.state.data
                  /*.sort((a, b) => {
                          if (a.Mes > b.Mes) {
                            return 1;
                          } else return -1;

                          //  return 0;
                        })*/
                  .map((item, i) => {
                    return (
                      <div
                        className="col-md-2 user-appointment"
                        key={i}
                        onClick={() =>
                          this.setState({
                            form1: false,
                            form2: true,
                            user: item.Data
                          })
                        }
                      >
                        <div className="card card-info card-annoucement card-round">
                          <div className="card-body text-center">
                            <div className="card-opening">
                              Citas del mes de <br />
                              {item.Mes.toUpperCase()}
                            </div>
                            <div className="card-desc">
                              ({item.Data.length}) citas.
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              {this.state.form2 && (
                <>
                  <div className="table-responsive">
                    <div
                      className="table-responsive"
                      style={{ paddingLeft: 65, paddingRight: 65 }}
                    >
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Nº de expediente</th>
                            <th>Paciente</th>
                            <th>Dni</th>
                            <th>Tipo de cita</th>
                            <th>Fecha de cita</th>
                            <th>Estado</th>
                            <th>Ingreso</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>

                  {this.state.user
                    .slice(this.state.count, this.state.count + 5)
                    .sort((a, b) => {
                      if (a.date > b.date) {
                        return 1;
                      } else return -1;
                    })
                    .map((item, p) => (
                      <div
                        className="table-responsive"
                        key={p}
                        style={{ paddingLeft: 15 }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            justifyContent: "left",
                            alignItems: "center"
                          }}
                        >
                          <h4
                            style={{
                              fontFamily: "Poppins",
                              color: "#19769f",
                              fontWeight: "bold"
                            }}
                          >
                            {moment(item.date).format("DD/MM/YYYY")}
                          </h4>
                        </div>

                        <div
                          className="table-responsive"
                          style={{ paddingLeft: 70, paddingRight: 10 }}
                        >
                          <table className="table table-striped">
                            <tbody>
                              {item.patients.map((user, u) => {
                                /*let disabled = true;

                                      var f1 = new Date(moment()); //31 de diciembre de 2015
                                      var f2 = new Date(moment(item.date));

                                      f1.setHours(0, 0, 0, 0);
                                      f2.setHours(0, 0, 0, 0);
                                      if (f1.getTime() >= f2.getTime()) {
                                        disabled = false;
                                      }

                                      if (f1.getTime() < f2.getTime()) {
                                        disabled = true;
                                      }*/

                                return (
                                  <tr key={u}>
                                    <td>{user.user.identificator}</td>
                                    <td className="font-weight-600">
                                      {user.user.name} {user.user.lastname}
                                    </td>
                                    <td className="font-weight-600">
                                      {user.user.dni}  
                                    </td>
                                    <td>
                                      <div className="badge badge-primary">
                                        {user.type_appointment}
                                      </div>
                                    </td>
                                    <td>
                                      {moment(item.date).format("DD/MM/YYYY")}
                                    </td>
                                    <td>
                                      {user.status === "check_in"
                                        ? "En proceso"
                                        : "Confirmado"}
                                    </td>
                                    <td>
                                      <button
                                        // disabled={disabled}
                                        className="btn btn-primary btn-sm"
                                        onClick={() =>
                                          this.handleSubmit(item, user)
                                        }
                                      >
                                        Dar Ingreso
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
            <br />
            <div className="align-content-center justify-content-center">
              <ul className="pagination justify-content-center">
                <li className="page-item">
                  {this.state.count > 0 ? (
                    <button
                      onClick={this.decrement.bind(this)}
                      className="page-link"
                    >
                      <i className="fas fa-angle-double-left"></i>
                    </button>
                  ) : (
                    false
                  )}
                </li>
                {createTable(finit)}
                {this.state.count + 5 < finit * 5 ? (
                  <li className="page-item">
                    <button
                      onClick={this.increment.bind(this)}
                      className="page-link"
                    >
                      <i className="fas fa-angle-double-right"></i>
                    </button>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        </div>
      </PrivateSecurity>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.reducer.user,
    confirmed: state.reducer.confirmed
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { appointmentCheckIn, initConsultation, showModal },
    dispatch
  );

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DoctorConsultation);
