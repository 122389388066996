import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const orden = [
  "Labios",
  "Lengua",
  "Carrillos",
  "Ganglios",
  "Mucosa",
  "Cálculo",
  "Encías",
  "A.T.M",
  "Paladar",
  "Orofaringe",
  "Gingiva",
  "Rebordes",
  "Dientes",
  "Oclusión",
  "Piso de Boca"

];

class OralClinicalExamination extends Component {
  componentDidMount() {}

  render() {
    const { formulary } = this.props;
    let objsOrdenados = [];
    orden.forEach((elem1, i) => {
      formulary.formulary &&
        formulary.formulary.items.forEach((elem2, i) => {
          if (elem2.title.es === elem1) {
            objsOrdenados.push(elem2);
          }
        });
    });

    return (
      <div className="col-md-12">
        <div className="card full-height">
          <div className="card-header">
            <div className="card-title">{this.props.title}</div>
          </div>
          <div className="card-body">
            <div className="row">
              {objsOrdenados.length > 0 &&
                objsOrdenados.map((item, i) => (
                  <div className="col-md-6" key={i}>
                    <div className="row">
                      <div className="col-md-3">
                        <h6
                          className="text-uppercase fw-bold mb-1"
                          style={{ fontSize: ".65rem" }}
                        >
                          {item.title.es === "Neurologico"
                            ? "Neurológico"
                            : item.title.es}
                        </h6>
                      </div>
                      <div className="col-md-10">
                        <span className="text-muted">
                          <Field
                            name={item.title.es}
                            className="form-control"
                            component="textarea"
                          />
                        </span>
                        <span className="text-warning pl-3"></span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    formulary: state.reducer.formulary
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  reduxForm({
    form: "OralClinicalExamination"
  })
)(OralClinicalExamination);